<template>
  <div
    class="flex flex-col items-center justify-center w-full pb-20 bg-[#F2F6FA]"
  >
    <section
      class="flex flex-col items-center w-full justify-center xl:w-[1285px]"
    >
      <div
        class="flex items-center justify-start md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full xl:w-[1285px]"
      >
        <div class="items-center gap-2 px-4 mt-5 -ml-2 d-flex">
          <img
            src="../../assets/img/sidebar/post.svg"
            class="cursor-pointer w-6 md:w-[28px]"
            alt="post"
          />
          <div class="flex items-center gap-1">
            <p class="text-base md:text-[22px] font-bold">
               Posts <span class="md:hidden">></span>
            </p>
            <p class="text-[#8056DE] text-sm font-semibold md:hidden">
              &nbsp;{{ selectedTabName }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="w-full pb-[33px] mt-5 bg-white md:px-4 xl:px-10 md:rounded-3xl"
      >
        <div class="md:py-[28px] xl:w-[1285px] flex gap-4 font-semibold w-full">
          <div
            class="bg-[#F2F6FA] py-2 px-2 md:px-0 flex gap-1 md:gap-4 md:p-1 md:rounded-full w-full"
          >
            <button
              class="rounded-full py-2 md:py-[14px] flex items-center justify-center md:w-full text-xs md:text-base"
              :class="
                tab == 0
                  ? 'bg-[#8056DE] text-white '
                  : 'bg-white text-[#636B8C]'
              "
              @click="
                () => {
                  tab = 0;

                  resetLoader();
                }
              "
            >
              <img
                src="../../assets/img/post-icons/gallerygray.svg"
                alt="delete"
                class="w-[20px] h-[20px] md:mr-2"
                :class="tab == 0 ? 'hidden ' : ''"
              />
              <img
                src="../../assets/img/post-icons/gallerywhite.svg"
                alt="delete"
                class="w-[20px] h-[20px] md:mr-2"
                :class="tab == 0 ? '' : 'hidden'"
              />
              <div class="hidden md:block">Published</div>
            </button>
            <button
              class="rounded-full py-2 md:py-[14px] flex items-center justify-center md:w-full text-xs md:text-base"
              :class="
                tab == 1
                  ? 'bg-[#8056DE] text-white '
                  : 'bg-white text-[#636B8C]'
              "
              @click="
                () => {
                  tab = 1;
                  resetLoader();
                  // closeSearch(), resetFilter();
                }
              "
            >
              <img
                src="../../assets/img/post-icons/calendergray.svg"
                alt="delete"
                class="w-[20px] h-[20px] md:mr-2"
                :class="tab == 1 ? 'hidden' : ''"
              />
              <img
                src="../../assets/img/post-icons/calenderwhite.svg"
                alt="delete"
                class="w-[20px] h-[20px] md:mr-2"
                :class="tab == 1 ? '' : 'hidden'"
              />
              <div class="hidden md:block">Scheduled</div>
            </button>
            <!-- <button
              class="rounded-full py-2 md:py-[14px] flex items-center justify-center md:w-full text-xs md:text-base"
              :class="
                tab == 2
                  ? 'bg-[#8056DE] text-white '
                  : 'bg-white text-[#636B8C]'
              "
              @click="
                () => {
                  tab = 2;
                  resetLoader();
                }
              "
            >
              <img
                src="../../assets/img/post-icons/releasedgray.svg"
                alt="delete"
                class="w-[20px] h-[20px] md:mr-2"
                :class="tab == 2 ? 'hidden' : ''"
              />
              <img
                src="../../assets/img/post-icons/releasedwhite.svg"
                alt="delete"
                class="w-[20px] h-[20px] md:mr-2"
                :class="tab == 2 ? '' : 'hidden'"
              />
              <div class="hidden md:block">Released</div>
            </button> -->
            <button
              class="rounded-full py-2 md:py-[14px] flex items-center justify-center md:w-full text-xs md:text-base"
              :class="
                tab == 3
                  ? 'bg-[#8056DE] text-white '
                  : 'bg-white text-[#636B8C]'
              "
              @click="
                () => {
                  tab = 3;
                  resetLoader();
                }
              "
            >
              <img
                src="../../assets/img/post-icons/draftgray.svg"
                alt="delete"
                class="w-[20px] h-[20px] md:mr-2"
                @click="
                  () => {
                    tab = 3;
                    resetLoader();
                  }
                "
                :class="tab == 3 ? 'hidden' : ''"
              />
              <img
                src="../../assets/img/post-icons/draftwhite.svg"
                alt="delete"
                class="w-[20px] h-[20px] md:mr-2"
                @click="
                  () => {
                    tab = 3;
                    resetLoader();
                  }
                "
                :class="tab == 3 ? '' : 'hidden'"
              />
              <div class="hidden md:block">Drafts</div>
            </button>
            <button
              @click="
                () => {
                  tab = 4;
                  resetLoader();
                  // closeSearch(), resetFilter();
                }
              "
              class="rounded-full py-2 md:py-[14px] flex items-center justify-center md:w-full text-xs md:text-base"
              :class="
                tab == 4
                  ? 'bg-[#8056DE] text-white '
                  : 'bg-white text-[#636B8C]'
              "
            >
              <img
                src="../../assets/img/gallery-icons/delete.svg"
                alt="delete"
                class="w-[20px] h-[20px] md:mr-2"
                :class="tab == 4 ? 'hidden' : ''"
              />
              <img
                src="../../assets/img/post-icons/deletewhite.svg"
                alt="delete"
                class="w-[20px] h-[20px] md:mr-2"
                :class="tab == 4 ? '' : 'hidden'"
              />
              <div class="hidden md:block">Deleted</div>
            </button>
          </div>
        </div>
        <div
          class="bg-[#F2F6FA] pb-2 pt-2 md:pt-[33px] px-2 lg:px-5 md:rounded-3xl  "
        >
          <div class="grid grid-cols-1 gap-4 md:grid-cols-1 posts">
            <post-card
              v-for="(post, i) in posts"
              :post="post"
              :key="i"
              @onPostDelete="onPostDelete(i)"
              @onSocialPostDelete="onSocialPostDelete()"
            ></post-card>
          </div>
          <infinite-loading ref="loader" @infinite="fetchPosts">
            <div slot="spinner">
              <v-skeleton-loader
                class="mx-auto"
                type="image,article"
              ></v-skeleton-loader>
            </div>
            <div slot="no-results"></div>
          </infinite-loading>
          <template v-if="!isLoading && posts.length == 0 && tab == 0">
            <div class="mt-4 text-center">
              <h4 class="text-xl font-bold">No posts to display</h4>
            </div>
            <v-card class="mt-10" v-if="tab == 0">
              <v-card-text>
                <h4
                  class="pb-1 mb-2 text-[26px] w-full mt-4 font-bold text-center leading-[57.20px] text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-blue-800"
                >
                  Yet to start with Fly?
                </h4>
                <v-container class="onboarding-section">
                  <v-row>
                    <v-col cols="12">
                      <router-link to="/connected-apps">
                        <div class="mb-10 onboarding-step">
                          <div class="number">1</div>
                          <span class="font-semibold"
                            >Connect to Social Handles</span
                          >
                        </div>
                      </router-link>
                      <router-link to="/create-post">
                        <div class="onboarding-step">
                          <div class="number">2</div>
                          <span class="font-semibold">Create New Post</span>
                        </div>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </template>
        </div>
      </div>
    </section>
  </div>
  <!-- <v-container style="max-width: 600px" class="py-10">
    <v-tabs
      class="mb-3"
      active-class="primary white--text"
      v-model="tab"
      @change="resetLoader"
    >
      <v-tab class="text-none custom-tab">All</v-tab>
      <v-tab class="text-none custom-tab">
        <i class="mr-1 fa fa-clock-o" style="font-size: 20px"></i>
        Scheduled
      </v-tab>
      <v-tab class="text-none custom-tab">
        <i class="mr-1 fa fa-send-o" style="font-size: 20px"></i>
        Released
      </v-tab>
      <v-tab class="text-none custom-tab">
        <v-icon class="mr-1">mdi-note-edit-outline</v-icon>
        Draft
      </v-tab>
    </v-tabs>

    <v-text-field
      placeholder="Type to Search here"
      outlined
      dense
      v-if="false"
      v-model="search"
    />
    <div class="posts">
      <post-card
        v-for="(post, i) in posts"
        :post="post"
        :key="i"
        @onPostDelete="onPostDelete(i)"
      ></post-card>
    </div>
    <infinite-loading ref="loader" @infinite="fetchPosts">
      <div slot="spinner">
        <v-skeleton-loader
          class="mx-auto"
          type="image,article"
        ></v-skeleton-loader>
      </div>
      <div slot="no-results"></div>
    </infinite-loading>
    <template v-if="!isLoading && posts.length == 0 && tab == 0">
      <div>
        <h4 class="text-xl">No posts to display</h4>
      </div>
      <v-card class="mt-10" v-if="tab == 0">
        <v-card-text>
          <h4
            class="pb-1 mb-2 text-[26px] w-full mt-4 font-bold text-center leading-[57.20px] text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-blue-800"
          >
            Yet to start with Fly?
          </h4>
          <v-container class="onboarding-section">
            <v-row>
              <v-col cols="12">
                <router-link to="/connected-apps">
                  <div class="mb-10 onboarding-step">
                    <div class="number">1</div>
                    <span class="font-semibold">Connect to Social Handles</span>
                  </div>
                </router-link>
                <router-link to="/create-post">
                  <div class="onboarding-step">
                    <div class="number">2</div>
                    <span class="font-semibold">Create New Post</span>
                  </div>
                </router-link>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </template>

    <v-btn
      class="rounded-btn"
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      @click="handleCreatePost"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="showDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">
          Time to renew!
          <v-icon color="error">mdi-bell-ring-outline</v-icon>
        </v-card-title>
        <v-card-text class="font-semibold">
          You have run out of credits. Please renew your subscription to keep
          posting!
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Close</v-btn>
          <v-btn @click="renewSubscription" color="primary"
            >Renew<v-icon>mdi-creation</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container> -->
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { auth, platforms } from "@/utils/firebase.utils.js";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { colUsers } from "@/utils/firebase.utils";
import moment from "moment";
import PostCard from "@/components/post/PostCard.vue";
import { getDate } from "@/utils/common.utils";

export default {
  components: {
    PostCard,
    InfiniteLoading,
  },
  data() {
    return {
      showDialog: false,
      socialAppLists: [],
      outputIds: [],
      posts: [],
      isLoading: false,
      searchEnable: false,
      search: "",
      tab: 0,
      startAfter: null,
      allPlatforms: [],
    };
  },
  computed: {
    selectedTabName() {
      switch (this.tab) {
        case 0:
          return "Published";
        case 1:
          return "Scheduled";
        case 2:
          return "Released";
        case 3:
          return "Drafts";
        case 4:
          return "Deleted";
      }
      return "";
    },
    filteredPosts() {
      console.log(this.tab, "tab");
      let list = [...this.posts];
      let value = this.search.trim().toLowerCase();
      list = list.filter((post) => {
        let text = post?.text.toLowerCase();
        let hashtags = post.hashtags?.toString().toLowerCase();
        return text.includes(value) || hashtags.includes(value);
      });
      if (this.tab == 1) {
        list = list.filter((i) => {
          let { when_to_post, scheduled_at } = i;
          let isFuture = moment().isBefore(moment(scheduled_at));
          return when_to_post == "schedule" && isFuture;
        });
        list.sort((a, b) =>
          moment(a.scheduled_at).diff(moment(b.scheduled_at))
        );
      }
      if (this.tab == 3) {
        console.log("Drafts");
        list = list.filter((i) => {
          let { when_to_post, scheduled_at } = i;
          let isPast = moment().isAfter(moment(scheduled_at));
          if (when_to_post == "schedule") return isPast;
          return true;
        });
      }
      return list;
    },
  },
  watch: {
    currentUID() {
      this.resetLoader();
    },
    WID() {
      this.resetLoader();
    },
  },
  methods: {
    onPostDelete(i) {
      this.posts.splice(i, 1);
    },
    onSocialPostDelete() {
      this.resetLoader();
    },
    resetLoader() {
      this.startAfter = null;
      this.posts = [];
      this.$refs.loader.stateChanger.reset();
    },
    handleCreatePost() {
      if (this.authUser.fly_credits > 0) {
        this.$router.replace("/create-post");
      } else {
        this.showDialog = true;
      }
    },
    closeDialog() {
      this.showDialog = false;
    },
    renewSubscription() {
      this.$router.push("/subscription");
      this.closeDialog();
    },
    openNewPostDialog() {
      // Add logic to open a new post dialog or perform any other action
      console.log("Opening new post dialog...");
    },
    async fetchPosts($state) {
  const vm = this;
  let currentUID = this.currentUID || vm.uid;
  if (!vm.currentUID && !vm.WID) return;
  try {
    vm.isLoading = true;
    let col = collection(
      colUsers,
      currentUID,
      "fly_workspaces",
      vm.WID,
      "social-posts"
    );
    let queries = [];
    
    // Fetch posts for the "All" tab (published and scheduled posts with past time)
    if (vm.tab == 0) {
      queries = [
        orderBy("created_at", "desc"),
        where("post_status", "==", "published")
      ];
    }
    
    if (vm.tab == 1) {
      let currentDate = this.getCurrentDate();
      queries.push(
        orderBy("scheduled_at", "asc"),
        where("scheduled_at", ">", currentDate),
        where("when_to_post", "==", "schedule")
      );
    }
    
    if (vm.tab == 2) {
      queries.push(
        orderBy("scheduled_at", "desc"),
        where("scheduled_at", "<", getDate()),
        where("when_to_post", "==", "schedule")
      );
    }
    
    if (vm.tab == 3) {
      queries.push(
        orderBy("created_at", "desc"),
        where("post_status", "==", "draft")
      );
    }
    
    if (vm.tab == 4) {
      queries.push(
        orderBy("created_at", "desc"),
        where("post_status", "==", "deleted")
      );
    }
    
    if (vm.startAfter) queries.push(startAfter(vm.startAfter));
    queries.push(limit(10));
    let q = query(col, ...queries);
    let docs = (await getDocs(q)).docs;

    let allPosts = docs.map((i) => {
      return {
        ...i.data(),
        id: i.id,
      };
    });

    // Filter scheduled posts whose time has passed
    // if (vm.tab == 0) {
    //   let currentDate = new Date();
    //   allPosts = allPosts.filter(post => {
    //     if (post.post_status === "deleted" || post.post_status === "draft") {
    //       return false;  // Exclude deleted posts
    //     }
    //     if (post.when_to_post === "schedule" && post.scheduled_at) {
    //       let scheduledDate = new Date(post.scheduled_at);
    //       return scheduledDate <= currentDate;  // Include only if the scheduled time is in the past
    //     }
    //     return post.post_status === "published" || post.when_to_post === "immediate";  // Include published posts
    //   });
    // }
    
    vm.startAfter = docs[docs.length - 1];
    const allPlatforms = await vm.getAllPlatforms();

    allPosts.map((post) => {
      let socialMediaArr = [];
      const socialMedias = post.social_media;

      function filteredApp(name) {
        const item = allPlatforms.filter((data) => {
          return data.alias == name;
        });

        return item;
      }
      
      socialMedias.map((media) => {
        const filteredItem = filteredApp(media);

        let data = {
          name: media,
          label: filteredItem[0]?.name,
          logo_url: filteredItem[0]?.logo_url,
        };
        socialMediaArr.push(data);
      });
      
      post.social_media = socialMediaArr;
    });
    
    vm.posts = [...vm.posts, ...allPosts];
    if (allPosts.length == 10) {
      $state.loaded();
    } else {
      $state.complete();
    }
    
    vm.isLoading = false;
  } catch (error) {
    $state.complete();
    vm.handleError(error);
  }
}


,

    async getAllPlatforms() {
      if (this.allPlatforms.length != 0) return this.allPlatforms;
      const platformsRef = await getDocs(platforms);

      let allPlatforms = [];

      platformsRef.forEach((doc) => {
        allPlatforms.push(doc.data());
      });

      allPlatforms.sort((a, b) => a.display_priority - b.display_priority);

      this.socialAppLists = allPlatforms;

      return allPlatforms;
    },
    getCurrentDate() {
      return moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    },
    hideZohoChat() {
      // if (typeof $zoho !== 'undefined' && typeof $zoho.salesiq !== 'undefined') {
      //   // Hide the Zoho Chat widget
      //   $zoho.salesiq.floatbutton.visible("hide");
      // }
      // setTimeout(() => $zoho.salesiq.floatbutton.visible("show"),60000)
    },
  },
  mounted() {
    this.hideZohoChat();
  },
};
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;
  z-index: 1;
}

.tooltip:hover::before {
  opacity: 1;
}
.custom-tab {
  min-width: 0; /* Allow the tabs to shrink */
  padding: 8px 16px; /* Adjust the padding to make them smaller */
  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis if the text overflows */
  overflow: hidden; /* Hide any overflow */
}
.rounded-btn {
  border-radius: 50%;
  position: fixed;
  bottom: 16px;
  right: 16px;
  /* width:50px;
  height: 50px; */
}
.onboarding-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.onboarding-step {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.onboarding-step .number {
  width: 24px; /* Adjust the width to make the circle smaller */
  height: 24px; /* Adjust the height to make the circle smaller */
  border-radius: 50%;
  background-color: #2196f3; /* Blue color for the numbers */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Adjust the font size if needed */
  margin-right: 12px;
}

.onboarding-step span {
  font-size: 18px;
  margin-right: 12px; /* Add margin to create space between the circle and text */
}
</style>
