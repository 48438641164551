<template>
  <div>
    <div class="flex items-start justify-center">
      <div class="w-full h-auto bg-white rounded-3xl">
        <div class="flex flex-col items-center px-4 py-5 md:pl-0 lg:px-5">
          <div class="flex items-center justify-between w-full">
            <div
              class="w-full text-sm font-medium text-neutral-900 cursor-pointer"
              @click="!is_cross_post ? openPostDetail() : null"
            >
              {{ post.name }}
            </div>

            <div v-if="!is_cross_post" class="flex gap-[14px] items-center mr-4">
              <div
                v-if="checkUID && (!post.post_status || (post.post_status && post.post_status !== 'deleted'))"
                @click="deletePost(post)"
                class="bg-[#F2F6FA] rounded-md p-[7px] cursor-pointer"
              >
                <img
                  src="../../assets/img/post-icons/deleteicon.svg"
                  alt="delete"
                  class="w-5"

                />
              </div>
              <div
                class="bg-[#F2F6FA] cursor-pointer rounded-md p-[7px]"
                @click="copyLink()"

              >
                <img
                  src="../../assets/img/post-icons/copyicon.svg"
                  alt="copy"
                  class="w-5"
                />
              </div>
              <a
                class="bg-[#F2F6FA] cursor-pointer rounded-md px-2 py-[0.15rem]"
                @click="openPostDetail()"
              >
                <v-icon class="eye-icon">
                  mdi-eye
                </v-icon>
              </a>
            </div>
          </div>
          <div class="flex flex-col md:flex-row w-full">
            <!-- Conditionally render Image Section only if there are carousels -->
            <div v-if="carousels.length" class="w-full md:w-[100px]">
              <v-carousel
                hide-delimiters
                v-model="carousel"
                class="mt-1 rounded-lg carousel-desktop-width"
                :show-arrows="carousels.length > 1"
                :height="isMobile ? '310px' : '100px'"
              >
                <v-carousel-item v-for="(item, index) in carousels" :key="index">
                  <div class="h-full d-flex align-center">
                    <v-img v-if="item.type == 'image'" :src="item.src" style=" object-fit: cover"  :height="isMobile ? '200px' : '100px'" />
                    <video-auto-play v-if="item.type == 'video'" :autoplay="false" :videoSrc="item.src" style="height: 200px; object-fit: cover" />
                  </div>
                </v-carousel-item>
              </v-carousel>
            </div>

            <!-- Text Section -->
            <div :class="{'w-full': carousels.length === 0, 'md:w-1/2': carousels.length > 0}" class="md:flex-1 text-xs font-normal text-justify text-slate-500 mt-4 md:mt-0 md:ml-4">
              <span>
                {{ showFullText ? post.text : shortText }}
              </span>
              <button
                v-if="post.text.length > 100"
                @click="showFullText = !showFullText"
                class="text-xs text-blue-600"
              >
                {{ showFullText ? "Show Less" : "Show More" }}
              </button>
              <div class="text-slate-500 mt-2">
                {{ formatHashtags(post.hashtags) }}
              </div>
              <div
                class="flex flex-wrap items-center justify-between w-full mt-4"
              >
                <div class="flex gap-[3px]">
                  <v-avatar
                    tile
                    size="24"
                    v-for="(socialMedia, index) in post.social_media"
                    :key="index"
                  >
                    <v-img
                      :src="socialMedia.logo_url"
                      :alt="socialMedia.name"
                    ></v-img>
                  </v-avatar>
                </div>

                <div class="mt-2 ml-1 text-xs font-normal text-slate-500">
                  <p v-if="!post.scheduled_at && post.created_at">
                    <span class="tooltip" data-tooltip="Sent"> </span>
                    {{ formatDate(post.created_at.seconds * 1000) }}
                  </p>
                  <p v-if="post.when_to_post != 'immediate'">
                    <span class="tooltip" data-tooltip="Schedule"> </span>
                    {{
                      post.scheduled_at.seconds
                        ? formatDate(post.scheduled_at.seconds * 1000)
                        : formatDate(post.scheduled_at)
                    }}
                  </p>
                </div>
              </div>
              <div class="status-container mr-7">
                <span v-if="post.post_status === 'deleted'" class="status-deleted text-xs bg-slate-100 rounded-3xl p-3">Deleted</span>
  <span v-else-if="post.post_status === 'draft'" class="status-draft text-xs bg-slate-100 rounded-3xl p-3">Draft</span>
  <span v-else-if="post.when_to_post === 'schedule' && !isPostLive(post)" class="status-scheduled text-xs bg-slate-100 rounded-3xl p-3">Scheduled</span>
  <span v-else-if="post.when_to_post === 'immediate' || (post.scheduled_at && isPostLive(post))" class="status-published text-xs bg-slate-100 rounded-3xl p-3">Published</span>
              </div>
            </div>
          </div>

          <div class="flex items-center justify-center mt-2 w-full">
            <div class="w-[350px] flex gap-[10px]">
              <button v-if="!is_cross_post"
                class="w-[100px] flex items-center justify-center gap-2 xl:w-[124px] py-[11px] text-[#636B8C] font-semibold bg-slate-100 rounded-full"
                @click="
                  post.post_status === 'draft'
                    ? editDraft(post.id)
                    : post.when_to_post !== 'immediate' && !isPostLive(post)
                    ? editPost(post.id)
                    : repost(post.id)
                "
              >
                <img src="../../assets/img/post-icons/repost.svg" alt="" />
                {{
                  post.post_status === "draft"
                    ? "Edit"
                    : post.when_to_post !== "immediate" && !isPostLive(post)
                    ? "Edit"
                    : "Repost"
                }}
              </button>

              <button
                v-if="!post.post_status || (post.post_status && post.post_status !== 'deleted')"
                class="w-[100px] flex items-center justify-center gap-2 xl:w-[124px] py-[11px] text-[#636B8C] font-semibold bg-slate-100 rounded-full"
                @click="showOutput = !showOutput"
              >
                <img src="../../assets/img/post-icons/graph.svg" alt="" />
                {{"Stats"}}
                <v-icon v-if="showOutput">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </button>
            </div>
          </div>
        </div>
        <div v-if="showOutput" class="mt-2 w-full">
          <post-output
            :scheduled_at="
              post.scheduled_at
                ? post.scheduled_at.seconds
                  ? formatDate(post.scheduled_at.seconds * 1000)
                  : post.scheduled_at
                : ''
            "
            :postUID="post.uid"
            :postId="post.id"
            :postwid="post.wid"
            :post="post"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, colUsers, colScheduledPostsDelete, colSocialPostsDelete } from "@/utils/firebase.utils";
import VideoAutoPlay from "../public/VideoAutoPlay.vue";
import PostOutput from "./PostOutput.vue";
import { deleteDoc, doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
export default {
  components: { VideoAutoPlay, PostOutput },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    openOutput: {
      type: Boolean,
      default: false,
    },
    is_cross_post:{
      type: Boolean,
      default:false
    },
  },
  data() {
    return {
      showFullText: false,
      carousel: 0,
      showOutput: false,
      draftLoading: false,
      checkUser: false,
      loading: false,
      approveButtonText: "",
      openRejectDialog: false,
      rejectComment: "",
      postId: this.$route.params.id,
    };
  },

  computed: {
    checkUID() {
      if (auth.currentUser.uid == this.currentUID || (this.post.post_status == 'draft' && (this.post.postBy == auth.currentUser.uid))) return true;
      else return false;
    },
    shortText() {
      const maxLength = 100; // Max length of visible text when not expanded
      if (this.post.text && this.post.text.length > maxLength) {
        return this.post.text.substring(0, maxLength) + '...';
      }
      return this.post.text;
    },
    carousels() {
      let list = [];
      if (this.post.videos) {
        this.post.videos.forEach((item) => {
          list.push({
            src: item.url || item,
            type: "video",
          });
        });
      }
      if (this.post.images) {
        this.post.images.forEach((item) => {
          list.push({
            src: item.thumbnail || item.url || item,
            type: "image",
          });
        });
      }
      if (this.$route.params?.uid === auth.currentUser.uid) {
        this.checkUser = true;
        if (this.post.scheduled_at) this.approveButtonText = "Approve and Schedule"
        else this.approveButtonText = "Approve and Publish"
      }
      return list;
    },
    hasIg() {
      return (
        this.post.social_media?.includes("instagram") ||
        this.post.social_media?.some((social) => social.name === "instagram")
      );
    },
    hasTh() {
      return (
        this.post.social_media?.includes("threads") ||
        this.post.social_media?.some((social) => social.name === "threads")
      );
    },
  },
  methods: {
    formatHashtags(tags) {
      if(tags) {
        return tags
          .map((i) => {
            if (i.toString().startsWith("#")) return i;
            return `#${i}`;
          })
          .map((i) => i.split(" ").join(""))
          .toString()
          .split(",")
          .join(" ");
      }
      return '';
    },
    copyLink() {
      let uid = this.post.uid || this.$route.params.uid;
      let wid = this.post.wid || this.$route.params.wid || this.WID;
      let id = this.post.id || this.$route.params.id;
      const route = this.$router.resolve({
        name: "post",
        params: {
          uid: uid,
          wid: wid,
          id: id,
        },
      });
      let url = `${location.origin}${route.href}`;
      navigator.clipboard.writeText(url);
      this.$snackbar.show("URL Copied.");
    },
    openPostDetail() {
      let uid = this.post.uid || this.$route.params.uid;
      let wid = this.post.wid || this.$route.params.wid;
      let id = this.post.id || this.$route.params.id;
      this.$router.push({
        name: 'post',
        params: {
          uid: uid,
          wid: wid,
          id: id,
        }
      });
    },
    isPostLive(post) {
      const currentDate = new Date();
      const scheduledDate = new Date(post.scheduled_at);
      console.log(scheduledDate.getTime() < currentDate.getTime());
      return scheduledDate.getTime() < currentDate.getTime();
    },
    editPost(postId) {
      let id = postId ? postId : this.$route.params.id;
      this.$router.push({
        name: "edit-post",
        params: {
          postId: id,
        },
      });
    },
    getScheduledDate(scheduledDate) {
      if (typeof scheduledDate == "object" && scheduledDate.seconds) {
        return this.formatDate(scheduledDate.seconds * 1000);
      } else if (typeof scheduledDate == "string") {
        return this.formatDate(scheduledDate);
      } else {
        return scheduledDate;
      }
    },
    editDraft(postId) {
      let id = postId ? postId : this.$route.params.id;
      this.$router.push({
        name: "draft-post",
        params: {
          postId: id,
        },
      });
    },
    repost(postId) {
      let id = postId ? postId : this.$route.params.id;
      this.$router.push({
        name: "repost",
        params: {
          postId: id,
        },
      });
    },
    deletePost(post) {
      let postId = this.$route.params.id ? this.$route.params.id : post.id;
      let data = {
        uid: post.uid,
        wid: post.wid,
        postId: postId,
        deleted_at: new Date(),
      };
      let message = "";
      try {
        if (this.hasIg) {
          message = 'Instagram: Post needs to be deleted manually';
        }
        if (this.hasTh) {
          message = 'Threads: Post needs to be deleted manually';
        }
        this.$confirm.show({
          message: `Are you sure you want to delete this post?`,
          note: message,
          onConfirm: async () => {
            const postRef = doc(colUsers, post.uid, "fly_workspaces", post.wid, "social-posts", postId);
            let isPostPublished = this.isPostLive(post);
            try {
              if (post.post_status === "draft") {
                this.$loader.show(`Deletion in progress`);
                updateDoc(postRef, {
                  post_status: "deleted",
                });
                this.$snackbar.show("Post deleted successfully!");
                this.$emit("onPostDelete");
                this.$loader.hide();
                return;
              } else if (post.scheduled_at && !isPostPublished) {
                data.was_scheduled_for = new Date(post.scheduled_at);
                data.action = "delete";
                this.$snackbar.show(
                  "Your scheduled posts have been marked for deletion."
                );
                //mark this post for deletion in Firebase in fly_scheduled_posts_delete_requests collection
                await updateDoc(postRef, {
                  post_status: 'deleted',
                });
                await setDoc(doc(colScheduledPostsDelete, postId), data);
              } else {
                this.$snackbar.show(
                  "Your published posts have been marked for deletion."
                );
                //mark this post for deletion in Firebase in fly_published_posts_delete_requests collection
                await updateDoc(postRef, {
                  post_status: "deleted",
                });
                await setDoc(doc(colSocialPostsDelete, postId), data);
              }
            } catch (error) {
              this.$loader.hide();
              console.error("Error calling Cloud Function:", error);
              this.$alert.show("Something went wrong");
            }
          },
        });
      } catch (error) {
        console.error("Error deleting post:", error);
      }
    },
  },
  beforeMount() {
    this.showOutput = this.openOutput == true;
  },
};
</script>

<style scoped>
  .status-published {
    color: #8056DE;
  }
  .status-deleted {
    color: red;
  }
  .status-scheduled {
    color: green;
  }
  .status-draft {
    color: orange;
  }
  .status-container {
  display: flex;
  justify-content: flex-end; /* Aligns children (status spans) to the right */
  width: 100%; /* Ensures the div takes full width */
}
@media (min-width: 1024px) {
  .carousel-desktop-width {
    width: 100px;
  }
  .images{
    height:100px;
  }
}
@media (min-width: 768px) {

    .md\:w-1\/6 {
        width: 9.666667%;
    }
}
@media (min-width: 768px) {
  .v-carousel .v-window__prev, .v-carousel .v-window__next {
    width: 24px;  /* Adjust width as needed */
    height: 24px; /* Adjust height as needed */
    margin: 0 1px; /* Add 1px horizontal margin */
  }

  .v-carousel .v-window__prev .v-icon, .v-carousel .v-window__next .v-icon {
    font-size: 18px !important;
    margin-bottom: 10px !important;
    margin-right: 9px !important;
  }
}
.eye-icon{
  font-size: initial;
}
</style>
