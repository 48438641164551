<template>
    <div data-v-000dea5e="" class=" flex-d1 bg-[#F7F6FF] gap-11 font-poppins py-11 rounded-3xl w-full md:w-auto">
        <div data-v-000dea5e="">
            <p data-v-000dea5e="" class="text-3xl font-semibold text-center mb-4">
                Subscription
            </p>
            <div class="flex gap-12 flex-wrap max-md:justify-center max-md:gap-3">
                <div data-v-c5917f36="" data-v-000dea5e="" style="font-family: Inter, sans-serif;">
  <section data-v-c5917f36="" class="flex flex-wrap justify-center gap-6 pb-12">
    <article data-v-c5917f36="" class="w-[280px] max-md:w-[220px] border text-center rounded-2xl bg-white hover:bg-gray-50 border-gray-300 transition-transform transform">
      
      <header data-v-c5917f36="" class="pt-6">
        <h1 data-v-c5917f36="" class="text-3xl max-md:text-2xl font-bold text-gray-800">
          Free Trial
        </h1>
      </header>
      
      <p data-v-c5917f36="" class="mt-4 text-lg max-md:text-[0.9rem] text-gray-600 px-4">
        Experience Insta-Everywhere free for 14 days
      </p>
      
      <ul data-v-c5917f36="" class="max-md:text-[0.9rem] mt-6 text-left text-gray-700 space-y-2 px-8">
        <li data-v-c5917f36="" class="flex items-center">
          <i data-v-c5917f36="" aria-hidden="true" class="v-icon notranslate mr-2 mdi mdi-check-circle theme--light" style="color: rgb(128, 86, 222); caret-color: rgb(128, 86, 222);"></i>
          Unlimited Access to All Features
        </li>
        <li data-v-c5917f36="" class="flex items-center">
          <i data-v-c5917f36="" aria-hidden="true" class="v-icon notranslate mr-2 mdi mdi-check-circle theme--light" style="color: rgb(128, 86, 222); caret-color: rgb(128, 86, 222);"></i>
          Free Cross Posting on all platforms
        </li>
      </ul>
      
      <div data-v-c5917f36="" class="flex flex-col gap-4">
        <div data-v-c5917f36="" class="px-9">
          <button  @click="loginHandle" data-v-c5917f36="" class="rounded-md py-2 px-3 w-full text-white text-sm font-bold mt-6 bg-[#8056DE]">
            Sign up Now
          </button>
        </div>
        <p data-v-c5917f36="" class="mb-4"></p>
      </div>

    </article>
  </section>
</div>

            <div data-v-000dea5e="" style="font-family: Inter, sans-serif;">
                <div>
                    <!-- Subscription Section -->
                    <section class="flex flex-wrap justify-center gap-4 pb-10 xl:gap-1">
                        <div
                            class="px-10 p-4 md:p-2 border rounded-2xl md:w-[280px] bg-white hover:bg-gray-100 border-gray-300">
                            <h1 class="text-3xl font-bold flex justify-center pt-3 text-gray-800">
                                Annual
                            </h1>
                            <h1 class="mt-4 text-sm flex justify-center text-gray-600"></h1>
                            <h4
                                class="mt-2 text-3xl font-semibold flex flex-col justify-center items-center text-gray-800">
                                ₹ 2499
                            </h4>
                            <div class="flex flex-col gap-2 justify-center items-center">
                                <div>
                                    <span class="text-gray-400 text-xl line-through pt-1 font-medium">₹ 4999</span>
                                </div>
                                <div>
                                    <span class="text-gray-400 text-xl">Per Year</span>
                                </div>
                                <div class="mt-5">
                                    <button type="button" class="v-btn v-btn--has-bg theme--light v-size--default black"
                                        style="height: 50px;">
                                        <span class="v-btn__content">
                                            <span class="text-white text-xl">50% OFF</span>
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <!-- Subscribe Button -->
                            <div>
                                <button
                                    class="bg-[#8056DE] mb-6 rounded-md py-2 px-3 w-full text-white text-sm font-bold mt-6"
                                    @click="loginHandle">
                                    Sign Up Now
                                </button>
                            </div>
                        </div>
                        <div class="mb-14"></div>
                    </section>
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <div class="w-full">
                            <!-- Additional Content (if any) -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import LiteTrial from "./LiteTrial.vue"
import LiteSubscription from '@/pages/fly-lite/LiteSubcription.vue';
import { auth, colUsers, db, platforms } from '@/utils/firebase.utils';
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc } from 'firebase/firestore';
import connectedAppsMixins from "@/mixins/connected.apps.mixins";
import { onAuthStateChanged } from 'firebase/auth';
import TestingPost from '@/components/post/TestingPost.vue';
import { functions } from "@/utils/firebase.utils";
import { httpsCallable } from "firebase/functions";
import PostCard from "@/components/post/Pc.vue";
import InfiniteLoading from "vue-infinite-loading";
export default {
    props: {
        checkAuth: {
            type: Boolean,
            default: false,
        },
        handleLogin: {
            type: Function,
            required: true
        }
    },
    components: {
        LiteSubscription,
        LiteTrial,
        TestingPost
    },
    data() {
        return {
            igPostDialog: false,
            phone_number: null,
            email: null, // Store email
            igPosts: [],
            isLoading: false,
            isActive1: false,
            isActive2: false,
            isActive3: false,
            isPaused: false,
            platforms: [
                { name: 'Facebook', picture: '', user_name: '', isConnected: false, id: 'facebook', user_id: null, pages: [], selectedProfiles: [] },
                { name: 'Threads', picture: '', user_name: '', isConnected: false, id: 'threads', user_id: null, pages: [], selectedProfiles: [] },
                { name: 'YouTube', picture: '', user_name: '', isConnected: false, id: 'youtube', user_id: null, pages: [], selectedProfiles: [] },
                { name: 'Google My Business', picture: '', user_name: '', isConnected: false, user_id: null, id: 'google_business_profile', pages: [], selectedProfiles: [] },
                { name: 'Pinterest', picture: '', user_name: '', isConnected: false, id: 'pinterest', user_id: null, pages: [], selectedProfiles: null },
                { name: 'Twitter', picture: '', user_name: '', isConnected: false, id: 'twitter', user_id: null, pages: [], selectedProfiles: [] },
                { name: 'LinkedIn', picture: '', user_name: '', isConnected: false, id: 'linkedin', user_id: null, pages: [], selectedProfiles: [] },
                { name: 'Tumblr', picture: '', user_name: '', isConnected: false, id: 'tumblr', user_id: null, pages: [], selectedProfiles: null },
            ],
            isAuthenticated: null,
            checkIsConnected: false,
            connectedApps: [],
            maxDisplay: 1,
            selectedPage: null,
            singlePagePlatforms: ['tumblr', 'reddit', 'pinterest'],
            instaPages: [],
            instaPageName: '',

        };
    },
    watch: {
        isAuthenticated() {
            this.fetchUserPhoneNumber()
        },
        checkAuth() {
            this.fetchConnectedApps();
        },
    },
    computed: {
        indicatorStyles1() {
            return { transform: this.isActive1 ? 'translateX(2rem)' : 'translateX(0)' };
        },
        indicatorStyles2() {
            return { transform: this.isActive2 ? 'translateX(2rem)' : 'translateX(0)' };
        },
        indicatorStyles3() {
            return { transform: this.isActive3 ? 'translateX(2rem)' : 'translateX(0)' };
        },
        authUserName() {
            return auth.currentUser ? auth.currentUser.displayName || "User" : "User";
        },
        dynamicGridClass() {
            const length = this.instaPages.length;
            if (length === 4) {
                return 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4';
            } else if (length === 3) {
                return 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3';
            } else if (length === 2) {
                return 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2';
            } else if (length === 1) {
                return 'grid grid-cols-1';
            } else {
                return 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5'; // Default for more than 4 items
            }
        },
    },
    mixins: [connectedAppsMixins],
    methods: {
        async fetchUserPhoneNumber() {
            try {

                const userRef = doc(db, "users", auth.currentUser.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    this.email = userData.email || "";
                    this.phone_number = userData.phone_number || null;
                    console.log(this.email, this.phone_number)
                }
            } catch (error) {
                console.error("Error fetching phone number:", error);
            }
        },
        checkChips(selectedProfiles) {
            if (selectedProfiles.length) return true
            return false;
        },
        fetchLabel(name) {
            let label;
            switch (name) {
                case 'facebook':
                case 'linkedin':
                    label = "Pages"
                    break;
                case 'instagram':
                    label = "Profiles"
                    break;
                case 'pinterest':
                    label = "Boards"
                    break;
                case 'reddit':
                    label = "Sub Reddits"
                    break;
                case 'tumblr':
                    label = "Blogs"
                    break;
                case 'google_business_profile':
                    label = "Locations"
                    break;
            }
            return label;
        },
        async fetchInstaPost() {
            const vm = this;
            vm.igPostDialog = true;
            let currentUID = this.currentUID || vm.uid;
            if (!vm.currentUID && !vm.WID) return;
            try {
                vm.isLoading = true;

                let fetchPostss = httpsCallable(functions, "instagramCrossPostsFetch");
                let result = (
                    await fetchPostss({
                        uid: 'Jqqrwr9DOWYK7tDByzoXUJWrMud2',
                        wid: 'default',
                        since: "test_user",
                    })
                ).data;
                let posts = result;

                vm.igPosts = [...posts];
                console.log("vm.posts:", vm.igPosts);
                vm.isLoading = false;
                vm.igPostDialog = false;
            } catch (error) {
                // vm.handleError(error);
            }
        },
        setDefaultImage(event) {
            event.target.src = require('@/assets/img/fly-lite/manager.png'); // Path to your default image
        },
        trimText(text, length = 20) {
            if (!text || typeof text !== 'string') {
                return '';
            }
            return text.length > length ? text.slice(0, length - 3) + '...' : text;
        },
        // Method to get trimmed text based on platform ID
        getTrimmedText(item, platformId) {
            // For 'google_business_profile' use 'title', otherwise 'name'
            const text = platformId === 'google_business_profile' ? item.title : item.name;
            return this.trimText(text);
        },
        selectPage(user_id, name) {
            this.selectedPage = user_id; // Set the selected page by user_id
            this.instaPageName = name;
            this.updateInstaprofile();
        },
        async pauseCrossPosting(checkPaused) {
            const docRef = doc(db, 'fly_crossplatform_instagram', `${this.uid}__default__instagram`);
            await setDoc(docRef, {
                paused: checkPaused,
                created_at: (new Date()).toISOString(),
                last_post_time: "",
            }, { merge: true })
        },
        async updateInstaprofile() {
            let page = null;
            this.instaPages.map((item) => {
                if (item.user_id == this.selectedPage) {
                    page = item;
                }
            });
            const docRef = doc(db, 'fly_crossplatform_instagram', `${this.uid}__default__instagram`);
            await setDoc(docRef, {
                configuration: page,
                created_at: (new Date()).toISOString(),
                last_post_time: "",
            }, { merge: true })
        },
        async updateCrossSetting() {
            let action = null;
            if (this.isActive1) {
                action = "all_posts"
            }
            else if (this.isActive2) {
                action = "reels"
            }
            else {
                action = "single_image"
            }
            const docRef = doc(db, 'fly_crossplatform_instagram', `${this.uid}__default__instagram`);
            await setDoc(docRef, {
                action: {
                    type: action
                },
                created_at: (new Date()).toISOString(),
                last_post_time: "",
            }, { merge: true })
            this.$snackbar.show("Updated cross platform post settings");
        },
        async updateCrossPlatforms() {
            const profiles = [];
            this.platforms.map((item) => {
                if (item.isConnected) {
                    profiles.push({
                        name: item.id,
                        pages: Array.isArray(item.selectedProfiles) ? item.selectedProfiles : item.selectedProfiles ? [item.selectedProfiles] : [],
                        user_id: item.user_id,
                    })
                }
            })
            const docRef = doc(db, 'fly_crossplatform_instagram', `${this.uid}__default__instagram`);
            await setDoc(docRef, {
                platforms: profiles,
                created_at: (new Date()).toISOString(),
                last_post_time: "",
                uid: this.uid,
                wid: 'default'
            }, { merge: true })
            this.$snackbar.show("Updated cross platform post settings");
        },
        isSelected(user_id) {
            // if(!this.selectedPage) this.checkIsConnected= false;
            // else this.checkIsConnected = true;
            return this.selectedPage === user_id; // Return true if the page is selected
        },
        checkMultiPlatform(name) {
            if (this.singlePagePlatforms.includes(name)) return true;
            return false;
        },
        async fetchConnectedApps() {
            const collectionRef = collection(db, 'users', this.uid, 'fly_workspaces', 'default', 'connected-apps');
            const docRef = doc(db, 'fly_crossplatform_instagram', `${this.uid}__default__instagram`);
            const docSnap = await getDoc(docRef);
            // Set up a real-time listener
            onSnapshot(collectionRef, (querySnapshot) => {
                const connectedAppsMap = {}; // Use a map for easier access by ID
                this.connectedApps = []; // Clear out previous data
                // Iterate over each document to gather connected apps
                querySnapshot.forEach((doc) => {
                    let childrenArray = doc.data().pages ||
                        doc.data().profiles ||
                        doc.data().boards ||
                        doc.data()["sub-reddits"] ||
                        doc.data().blogs ||
                        (Array.isArray(doc.data()?.accounts) && doc.data().accounts[0]?.locations) ||
                        doc.data().users || [];

                    // Special handling for LinkedIn to include user_id
                    if (doc.id === 'linkedin') {
                        childrenArray = childrenArray.map((d) => ({
                            ...d,
                            user_id: doc.data().user_id
                        }));
                    }
                    if (doc.id === 'instagram') {
                        this.instaPages = childrenArray;
                    }
                    // Add connected app to connectedApps array
                    const connectedAppData = {
                        id: doc.id,
                        ...doc.data(),
                        pages: childrenArray // Store childrenArray in pages field
                    };
                    this.connectedApps.push(connectedAppData);

                    // Add the connected app to the map for faster lookup
                    connectedAppsMap[doc.id] = connectedAppData;
                });

                // Update platforms based on connected apps
                this.platforms = this.platforms.map((platform) => ({
                    ...platform,
                    isConnected: !!connectedAppsMap[platform.id], // Use the map for fast lookup
                    pages: connectedAppsMap[platform.id]?.pages || [], // Assign pages if connected, otherwise empty array
                    user_id: connectedAppsMap[platform.id]?.user_id || connectedAppsMap[platform.id]?.name,
                    picture: connectedAppsMap[platform.id]?.picture || '',
                    user_name: connectedAppsMap[platform.id]?.name || 'Admin',
                    selectedProfiles: !connectedAppsMap[platform.id]
                        ? platform.selectedProfiles
                        : (['tumblr', 'pinterest'].includes(platform.id) ? null : []), // Correct condition check
                }));
                // Check if Instagram is connected
                this.checkIsConnected = !!connectedAppsMap['instagram']; // Direct lookup from map
                this.$emit('checkInsta', this.checkIsConnected);
                if (docSnap.exists()) {
                    const docData = docSnap.data();
                    this.selectedPage = docData?.configuration.user_id;
                    this.instaPageName = docData?.configuration.user_name;
                    if (docData?.action?.type == "single_image") this.isActive2 = true;
                    else if (docData?.action?.type == "reels") this.isActive3 = true;
                    else if (docData?.action?.type == "all_posts") this.isActive1 = true;
                    docData?.platforms?.map((item) => {
                        this.platforms.some((platform) => {
                            if (platform.id === item.name) {
                                platform.selectedProfiles = ["pinterest", "reddit", "tumblr"].includes(item.name)
                                    ? item.pages[0] || null  // Single selection for certain platforms
                                    : item.pages || [];     // Multiple selections for others
                                return true;  // Exiting once a match is found
                            }
                        });
                    });
                }
                else {
                    this.isActive1 = true;
                }
            }, (error) => {
                console.error('Error fetching connected apps in real-time:', error);
            });
        },
        async disconnectInstagram(itemAlias) {
            this.disconnectConnection(itemAlias, 'personal')
            this.setDataDefault();
            this.setDefaultPlatSetting();
            const docRef = doc(db, 'fly_crossplatform_instagram', `${this.uid}__default__instagram`);
            await deleteDoc(docRef)
        },
        setDataDefault() {
            // Reset all the data to its default state
            this.isActive1 = true;
            this.isActive2 = false;
            this.isActive3 = false;
            this.selectedPage = null;
            this.instaPages = [];
            this.isAuthenticated = null,
                this.checkIsConnected = false,
                this.connectedApps = [],
                this.instaPageName = '';
        },
        loginHandle() {
            this.handleLogin();
        },
        toggle(index) {
            if (index === 1) {
                this.isActive1 = !this.isActive1;
                this.isActive2 = false;
                this.isActive3 = false;
            } else if (index === 2) {
                this.isActive2 = !this.isActive2;
                this.isActive1 = false;
                this.isActive3 = false;
            } else if (index === 3) {
                this.isActive3 = !this.isActive3;
                this.isActive2 = false;
                this.isActive1 = false;
            }
            this.updateCrossSetting();
        },
        getImageUrl(id) {
            return require(`@/assets/img/fly-lite/${id}.png`);
        },
        getPlatformIcon(id) {
            return require(`@/assets/img/social-logo/${id}.png`);
        },
        connectingHandle(itemAlias) {
            this.initConnection(itemAlias, "personal");
        },
        setDefaultPlatSetting(itemAlias) {
            this.platforms = this.platforms.map(platform => {
                if (platform.id === itemAlias) {
                    return {
                        ...platform, // Keep other properties
                        picture: '', // Reset platform-specific fields
                        user_name: '',
                        isConnected: false,
                        user_id: null,
                        pages: [],
                        selectedProfiles: itemAlias == "pinterest" || "tumblr" ? null : []
                    };
                }
                return platform; // Keep unchanged platforms intact
            });
            this.connectedApps = [];
        },
        async manageConnection(profile) {
            if (profile.isConnected) {
                const docRef = doc(db, 'fly_crossplatform_instagram', `${this.uid}__default__instagram`);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const platforms = docSnap.data().platforms || [];
                    // Filter out the object with name 'instagram'
                    const updatedPlatforms = platforms.filter(platform => platform.name !== profile.id);
                    // Update the document with the filtered platforms array
                    await updateDoc(docRef, {
                        platforms: updatedPlatforms
                    });
                }
                this.disconnectConnection(profile.id, "personal");
                this.setDefaultPlatSetting(profile.id);

            }
            else {
                this.connectingHandle(profile.id, "personal");
            }
            this.fetchConnectedApps();
        },
    },
    beforeMount() {
        // Same auth check logic as in 'created'
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.fetchUserPhoneNumber()
                this.fetchConnectedApps();
            }
        });
    },
};
</script>



<style scoped>
.linear-gradient {
    border-radius: 2rem;
    border: 2px solid #FB8C8C;
    background: linear-gradient(145deg, #FB8C8C 13.58%, #FF1D1D 86.93%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.flex-d {
    display: flex;
    flex-direction: column;
}

.flex-d1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fly-lite-setting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.125rem;
    border: 1px solid #E6E8EC;
    border-radius: 1rem;
    padding: 1rem 3rem;
    background: #FFF;
}

.gold-mid {
    background-color: #8056DD;
}

.gray-lighter {
    background-color: #CBD5E1;
}

.toggle-wrapper {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 4.5rem;
    height: 2.5rem;
    border-radius: 9999px;
    transition: all 0.4s ease;
}

@media (max-width: 700px) {
    .toggle-wrapper {
        width: 3.6rem;
        height: 1.7rem;
    }
}

@media (max-width: 320px) {
    .toggle-wrapper {
        width: 3rem;
        height: 1.75rem;
    }

    .fly-lite-setting {
        font-size: 1rem;
        padding: 0.75rem 2rem;
    }
}

.toggle-wrapper:focus {
    outline: 0;
}

.toggle-background {
    display: inline-block;
    border-radius: 9999px;
    height: 100%;
    width: 100%;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.4s ease;
}

.toggle-indicator {
    position: absolute;
    height: 2rem;
    width: 2rem;
    left: 0.3rem;
    bottom: 0.25rem;
    background-color: #ffffff;
    border-radius: 9999px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease;
}

@media (max-width: 700px) {
    .toggle-indicator {
        height: 1.3rem;
        width: 1.3rem;
        left: 0.2rem;
    }
}

@media (max-width: 320px) {
    .toggle-indicator {
        height: 1.5rem;
        width: 1.5rem;
        left: 0rem;
    }
}

.flylite-connected {
    width: 14rem;
    border-radius: 2rem;
    padding: 0.2rem 1.7rem;
    border: 1.7px solid rgb(255 103 103);
    color: rgb(255 103 103);
}

.flylite-update {
    border-radius: 2rem;
    padding: 0.2rem 1.7rem;
    border: 1.7px solid rgb(5, 151, 0);
    color: rgb(5, 151, 0);
}

.flylite-not-connected {
    width: 14rem;
    border-radius: 2rem;
    padding: 0.2rem 1.7rem;
    border: 1.7px solid #9abcf5;
    color: #0847b5;
}

.plat-icon {
    background: white;
    border-radius: 5px;
    position: absolute;
    bottom: -5px;
    /* Adjust as per your design */
    right: -5px;
    /* Adjust as per your design */
    z-index: 1;
    /* Ensure it appears above the rounded div */

}

.green-check {
    background-color: #00C853;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    /* Ensure it's above other elements */
}

.green-check v-icon {
    font-size: 1rem;
    /* Adjust the size of the check icon */
}

.disabled {
    pointer-events: none;
    /* Prevent interaction */
    position: relative;
    opacity: 0.4;
    z-index: 1;
    /* Make it look disabled */
}

.disabled-card {
    /* Prevent interaction */
    position: relative;
    opacity: 0.5;
    z-index: 1;
    /* Make it look disabled */
}

.disabled_class {
    z-index: 50;
    opacity: 100 !important;
    /* Add a semi-transparent white background */
    /* Ensure it appears on top */
}

.selected-card {
    border: 2px solid #8056DD;
    /* Add a border to highlight the selected card */
    transform: scale(1.05);
    /* Slightly scale up the selected card */
    transition: transform 0.2s ease, border 0.2s ease;
}

.selected-card:hover {
    cursor: pointer;
    /* Make it clear the card is clickable */
}

.bg-white {
    transition: transform 0.2s ease, border 0.2s ease;
}

.combobox-container {
    max-width: 14rem;
    /* Set a max width for the combobox */
    /* overflow: hidden; */
    white-space: nowrap;
    /* Prevent overflow text */
    text-overflow: ellipsis;
    /* Add ellipsis for overflow text */
}

.v-combobox {
    max-width: 100%;
    /* Ensure the combobox does not overflow the div */
}

.combobox-container .v-input__control {
    max-width: 100%;
    /* Prevent the input control from overflowing */
}

.disabled_class {
    z-index: 10;
    opacity: 1;
    /* Ensure this text has full opacity */
}

.flex-d1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .combobox-container {
        max-width: 12rem;
        /* Adjust width for smaller screens */
    }
}

@media (max-width: 480px) {
    .combobox-container {
        max-width: 10rem;
        /* Further adjustment for very small screens */
    }
}

.floating-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    /* Ensure it's above other elements */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.floating-btn {
    font-size: 1rem;
    /* Default for large screens */
}

@media (max-width: 1024px) {

    /* For tablets */
    .floating-btn {
        font-size: 0.9rem;
    }
}


@media (max-width: 640px) {

    /* For smaller devices */
    .floating-btn {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {

    /* For phones */
    .floating-btn {
        font-size: 0.8rem;
    }
}


@media (max-width: 768px) {
    .floating-btn {
        bottom: 15px;
        right: 15px;
        width: auto;
        padding: 10px 20px;
    }
}
</style>