<template>
  <div
    class="flex flex-col  w-full pb-20 bg-[#f3f4f7]"
  >
    <section
      class="flex flex-col items-center w-full justify-center "
    >
    <div
  class="flex items-center justify-between md:mt-5 mb-2 md:mb-0 mt-2 px-2 md:px-0 w-full "
>
 <div class="flex items-center gap-2 px-4 mt-5 -ml-2 w-full justify-between">
  <div class="flex items-center gap-1">
    <header class="flex items-center px-6 py-4 lg:flex-none ">
      <div class="date-container">
        <h1 class="text-base font-semibold leading-6 text-gray-900">
          <time :datetime="currentDate.toISOString()" v-text="formatDate(currentDate)"></time>
        </h1>
      </div>
    </header>
  </div>

  <!-- Centered View Switch Buttons and Today Button -->
  <div class="flex items-center gap-4">
    <div class="inline-flex gap-2">
  <!-- Week Button -->
  <div class="px-2.5 py-2.5 rounded-md justify-start items-start gap-2.5 flex cursor-pointer border"
    :class="{
      'bg-[#8056dd] border-[#8056dd] text-white font-bold': currentView === 'week',
      'border-[#8056dd] text-[#667790] font-medium': currentView !== 'week'
    }"
    @click="setView('week')"
  >
    <div class="text-sm font-['DM Sans'] leading-none">
      Week
    </div>
  </div>

  <!-- Month Button -->
  <div class="px-2.5 py-2.5 pt-[10px] rounded-md justify-start items-start gap-2.5 flex cursor-pointer border"
    :class="{
      'bg-[#8056dd] border-[#8056dd] text-white font-bold': currentView === 'month',
      'border-[#8056dd] text-[#667790] font-medium': currentView !== 'month'
    }"
    @click="setView('month')"
  >
    <div class="text-sm font-['DM Sans'] leading-none">
      Month
    </div>
  </div>
</div>


    <div class="flex items-center justify-center ">
  <!-- Previous Button -->
  <button
    type="button"
    :disabled="loading"
    class="h-[34px] px-2 py-[7px] bg-[#8056dd] justify-center items-center gap-2 inline-flex rounded-l-md"
    @click="previousMonth"
  >
    <div class="w-4 h-4 relative">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
      </svg>
    </div>
  </button>

  <!-- Today Button (centered between Previous and Next) -->
  <button
    type="button"
    :disabled="loading"
    class="h-[34px] px-2 py-[7px] bg-[#8056dd] justify-center items-center inline-flex"
    @click="goToToday"
  >
    <div class="text-white text-sm font-medium font-['Inter'] leading-tight">Today</div>
  </button>

  <!-- Next Button -->
  <button
    type="button"
    :disabled="loading"
    class="h-[34px] px-2 py-[7px] bg-[#8056dd] rounded-r-md justify-center items-center gap-2 inline-flex"
    @click="nextMonth"
  >
    <div class="w-4 h-4 relative">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
      </svg>
    </div>
  </button>
</div>

  </div>
</div>


  <!-- Connect button aligned to the full right -->
  <div class="flex justify-end w-full mt-7">
  <button
    v-if="connectedAppsLoaded && connectedAppsCount === 0"
    class=" text-white px-4 py-2 rounded-full font-semibold"
    @click="navigateToConnectedApps"
  >
 
  </button>
</div>

</div>
<div   v-if="connectedAppsLoaded && connectedAppsCount === 0" class=" h-[124px] pl-12 pr-8 py-8 bg-[#8056dd] rounded-2xl justify-start items-center gap-7 inline-flex w-[95%] mb-2">
  <div class="w-[811px] flex-col justify-start items-start gap-2 inline-flex">
    <div class="w-[861px] text-white text-[40px] font-semibold font-['Inter'] leading-[48px]">
      Start by connecting social handles
    </div>
  </div>
  

  <!-- Button Container -->
  <div class="pl-6 pr-4 py-5 bg-white rounded-md border border-[#e6e8ec] justify-center w-10 items-center gap-2 flex cursor-pointer">
    <div class="text-black text-[22px] font-semibold font-['Inter'] leading-tight"  @click="navigateToConnectedApps">
      Connect Now
    </div>
    <div class="w-4 h-4 relative"></div>
  </div>
</div>

      <div
        class="w-full pb-[33px]  md:px-4 xl:px-10 md:rounded-3xl  max-md:max-w-full"
      >
      
        <div
          class="lg:flex lg:h-full lg:flex-col lg:block main-container"
          style="position: relative"
        >
        
          <!-- Header and Navigation -->

          <!-- Days of Week Header -->
          <div class="h-12 py-4 bg-white rounded-lg justify-start items-center inline-flex w-full ">
  <div
    v-for="(dayName, index) in ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']"
    :key="dayName"
    class="text-black text-sm font-bold font-['DM Sans'] leading-none"
    :class="{
      'h-12 py-4 flex-1 text-center': true, // Flex to take equal space and text centered
      'rounded-l-md': index === 0, // Rounded on the left for Sunday
      'rounded-r-md': index === 6, // Rounded on the right for Saturday
    }"
  >
    {{ dayName }}
  </div>
</div>


          <!-- Main Content Container -->
          <div class="main-content lg:flex pt-[20px]" v-if="currentView === 'month'">
            <!-- Calendar Container -->
            <div
              class="flex text-sm leading-6 text-gray-700 lg:flex-auto  pb-0"
            >
              <div
                class="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 "
              >
                <div
                  v-for="day in monthDays"
                  :key="day.toISOString()"
                  class="relative p-4 bg-white shadow-sm date-cell cursor-pointer"
                  :class="{
                    'bg-white': day.getMonth() === currentDate.getMonth(),
                    'bg-gray-50 text-gray-500':
                      day.getMonth() !== currentDate.getMonth(),
                    'border border-[#8050DE] rounded-lg': isToday(day),
                     'border border-[#e6e8ec]': !isToday(day),
                     'opacity-50': day.getMonth() !== currentDate.getMonth() // Apply opacity-50 only for previous months
                  }"
                >
                  <div class="relative flex justify-between items-center">
                    <time
  :datetime="day.toISOString()"
  :class="{
    'text-blue-600 text-lg  font-bold': isToday(day),  // Styles when it is today
    'text-black text-lg font-medium  leading-normal': !isToday(day)  // Styles when it is not today
  }"
  v-text="formatDay(day)"
></time>

                    <div class="plus-btn-container">
                      <button
                        v-if="isTodayOrAfter(day) && checkWorkspaceRole"
                        @click.stop="schedule(day)"
                        class="plusbtn text-lg text-blue-600 mb-2"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="getHolidayName(day) && !loading"
                    class="holiday-name text-[#e97284]  px-2 py-1.5 bg-[#f5f0ff] rounded-md  mt-2 text-xs flex-wrap font-semibold font-['Inter'] leading-[18px]"
                  >
                    {{ truncateText(getHolidayName(day), 16) }}
                  </div>
                  <div
                    v-if="loading"
                    class="skeleton-loader mt-2 max-h-48 overflow-auto"
                  >
                    <div
                      class="skeleton-post-container flex justify-between mt-1 cursor-pointer p-2 bg-gray-200 rounded-md"
                    >
                      <div
                        class="skeleton-platform-logo w-2 h-2 bg-gray-300 rounded"
                      ></div>
                      <div
                        class="skeleton-text w-full bg-gray-300 rounded ml-2 h-2"
                      ></div>
                    </div>
                    <div
                      class="skeleton-post-container flex justify-between mt-1 cursor-pointer p-2 bg-gray-200 rounded-md"
                    >
                      <div
                        class="skeleton-platform-logo w-2 h-2 bg-gray-300 rounded"
                      ></div>
                      <div
                        class="skeleton-text w-full bg-gray-300 rounded ml-2 h-2"
                      ></div>
                    </div>
                  </div>

                  <div
                    v-if="
                      !loadingStates[day.toISOString().split('T')[0]] &&
                      posts &&
                      posts[day.toISOString().split('T')[0]]
                    "
                    class="mt-2 max-h-48 overflow-auto"
                  >
                    <div
                      v-for="(post, index) in displayedPosts(day).filter(
                        (post) => post.post_status !== 'deleted'
                      )"
                      :key="post.id"
                      class="post-container flex gap-3 mt-1 cursor-pointer p-2  hover:bg-gray-200 transition duration-300"
                      @click="selectedPost = post"
                    >
                      <div
                        v-if="
                          post.platformDetails &&
                          post.platformDetails?.length > 0
                        "
                        class="platform-logo relative"
                      >
                        <img
                          :src="post.platformDetails[0].logo_url"
                          :alt="post.platformDetails[0].label"
                          class="w-6 h-6"
                        />
                      </div>
                      <p v-if="!post.scheduled_at && post.created_at">
                        <span class="tooltip" data-tooltip="Created at"> </span>
                        {{ formatDatepost(post.created_at) }}
                      </p>
                      <p
                        v-if="
                          post.when_to_post != 'immediate' && post.scheduled_at
                        "
                      >
                        {{ formatTime(post.scheduled_at) }}
                      </p>
                      <div v-if="!post.scheduled_at && post.created_at"  class="w-4 h-4 relative ml-auto"><img  src="@/assets/img/right-n.svg"  class="cursor-pointer" /></div>
                    </div>
<div
  v-if="
    posts[day.toISOString().split('T')[0]] &&
    posts[day.toISOString().split('T')[0]].filter(
      (post) => post.post_status !== 'deleted'
    ).length > 2
  "
  @click="showPostsInSidebar(day)"
  :class="[
    'h-[25px] w-full rounded-md border justify-center items-center inline-flex cursor-pointer transition duration-300',
    day.toISOString().split('T')[0] === new Date().toISOString().split('T')[0]
      ? 'bg-[#8056dd] text-white'
      : 'bg-white text-[#8056dd] hover:bg-gray-200'
  ]"
  class="border-[#e6e8ec]"
>
  <div class="w-[22px] h-[22px] relative"></div>
  <div class="text-sm font-bold font-['DM Sans'] leading-none">
    + {{ getAdditionalPostsCount(day) }} more
  </div>
</div>

                  </div>
                </div>
              </div>
            </div>

            <!-- Sidebar for Selected Date -->
            <div
              v-if="sidebarOpen && currentView === 'month' && !isMobile"
              class="sidebar"
              :style="{
                transform: sidebarOpen ? 'translateX(0)' : 'translateX(100%)',
              }"
            >
              <div class="flex justify-between items-center mb-4 sticky-header">
                <div class="w-4 h-8 relative bg-[#8056dd] rounded"></div> 
                <div class="h-10 p-2 justify-start items-start gap-2.5 inline-flex">
    <div class="w-6 h-6 relative cursor-pointer">  <img  src="@/assets/img/arrow_bd.svg" @click="goToPreviousDay" class="cursor-pointer" /></div>
</div>
                <h2 class="text-black text-lg font-bold font-['SF Pro Display'] leading-normal">
                  {{ formatsideDate(selectedDay, "sidebar") }}
                </h2>
                <div class="h-10 p-2 justify-start items-start gap-2.5 inline-flex">
    <div class="w-6 h-6 relative">  <img  src="@/assets/img/arrow_fd.svg" @click="goToNextDay" class="cursor-pointer" /></div>
</div>
              
                <button
                  @click="closeSidebar"
                  class="text-lg text-gray-600"
                >
                  &#x2715;
                </button>
              </div>
              <div class="w-[202px] text-slate-400 text-xs font-bold font-['Inter'] leading-tight tracking-wide mb-2">POST LIST</div>
              <div v-if="posts[selectedDay.toISOString().split('T')[0]]">
  <div
    v-for="post in posts[selectedDay.toISOString().split('T')[0]].filter((post) => post.post_status !== 'deleted')"
    :key="post.id"
    class="w-[260px] h-[100px] p-3 bg-white rounded-xl border border-[#e6e8ec] justify-start items-center gap-3 inline-flex cursor-pointer hover:bg-gray-200 transition duration-300 mb-4"
    @click="selectedPost = post"
  >
    <!-- Left Image Container -->
    <div class="w-16 h-16 relative bg-[#cabdfe] rounded-lg">
  <div class="w-16 h-16 left-0 top-0 absolute bg-[#cabdfe] justify-center items-center inline-flex rounded-md">
    <img
      v-if="post.images && post.images.length > 0"
      class="w-[100.43px] h-[70.93px] rounded-lg"
      :src="post.images[0]"
      :alt="`Image for ${post.name}`"
      @error="post.images[0] = 'https://via.placeholder.com/121x81'"
    />
    <img
      v-else-if="post.videos && post.videos.length > 0"
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ63uWxSQ8n_tHow8Iymgy52T0rtBkDpyLa0BW8s54vRUwps2aHM441fbZsOyPrEAJUWWg&usqp=CAU"
      alt="Video thumbnail"
      class="w-[121.43px] h-[80.93px]"
    />
    <!-- Default image if no image or video -->
    <img
      v-else
      :src="require('@/assets/img/redirect/defaultImage.png')"
      alt="Default image"
      class="w-[121.43px] h-[80.93px]"
    />
  </div>
  
  <!-- Icon in the right-bottom corner -->
  <div v-if="!post.scheduled_at && post.created_at"
    class="absolute bottom-0 -right-2 h-6 p-1 bg-[#8056dd] rounded-[7px] flex items-center justify-center cursor-pointer"
  >
    <img src="@/assets/img/right-n-fill.svg" alt="Icon" />
  </div>
</div>


    <!-- Right Content Container -->
    <div class="grow shrink basis-0 flex flex-col justify-between items-start gap-1">
      <div class="flex justify-between w-full items-center">
        <div class="flex items-center gap-1">
          <div v-if="!post.scheduled_at && post.created_at" class="text-black text-[15px] font-bold font-['Inter'] leading-normal whitespace-nowrap">
  {{ formatTimesidebar(post.created_at) }}
</div>
<div    v-if="post.when_to_post != 'immediate' && post.scheduled_at" class="text-black text-[15px] font-bold font-['Inter'] leading-normal whitespace-nowrap">
  {{ formatTimesidebar(post.scheduled_at) }}
</div>

        </div>

        <!-- Platform Logo Section (right-aligned) -->
        <div class="flex items-center ml-[60px]">
          <div class="relative w-10 h-6">
            <img
              v-if="post.platformDetails && post.platformDetails.length > 0"
              :src="post.platformDetails[0].logo_url"
              :alt="post.platformDetails[0].label"
              class="absolute platform-logo w-6 h-6"
            />
            <img
              :src="getProfileImageUrl(post)"
              alt="Profile Image"
              class="absolute w-6 h-6 rounded-full"
              @error="handleImageError"
              style="left: 0.3rem"
            />
          </div>
        </div>
      </div>

      <!-- Post Text -->
      <div class="text-[#667790] text-[15px] font-medium font-['Inter'] leading-tight">
        {{ truncateText(post.text, 18) }}
      </div>
    </div>
  </div>
</div>


            </div>
          </div>

          <!-- Calendar Days Grid -->
          <div
            class="flex bg-gray-100 text-sm leading-6 text-gray-700 lg:flex-auto"
          >
            <div
              v-if="currentView === 'week'"
              class="w-full lg:grid lg:grid-cols-7 lg:gap-2 p-4 pb-0"
            >
              <div
                v-for="(day, index) in weekDays"
                :key="day.toISOString()"
                class="relative p-4 bg-white rounded-lg shadow-sm date-week"
                :class="{
                  'bg-white': day.getMonth() === currentDate.getMonth(),
                  'bg-gray-50 text-gray-500':
                    day.getMonth() !== currentDate.getMonth(),
                  'date-passed': isDatePassed(day),
                  'border border-[#8050DE] rounded-lg': isToday(day),
                }"
              >
                <div class="flex justify-between items-center">
                  <time
                    :datetime="day.toISOString()"
                    :class="{ 'text-blue-600 font-bold': isToday(day) }"
                    v-text="day.getDate()"
                  ></time>
                  <button
                    v-if="isTodayOrAfter(day) && checkWorkspaceRole"
                    @click.stop="schedule(day)"
                    class="plusbtn text-lg text-blue-600 mb-1"
                  >
                    +
                  </button>
                </div>
                <div
                  v-if="getHolidayName(day)"
                  class="holiday-name text-black bg-pink-100 rounded-md px-2 mt-2"
                >
                  {{ getHolidayName(day) }}
                </div>
                <div
  v-if="posts && posts[day.toISOString().split('T')[0]]"
  class="mt-2 max-h-48 overflow-y-auto"
  style="max-height: 500px; overflow-y: auto;" 
>
  <div
    v-for="(post, index) in displayedWeeklyPosts(day)"
    :key="post.id"
    class="post-container flex justify-between mt-1 cursor-pointer p-2 bg-gray-50 rounded-md hover:bg-gray-200 transition duration-300"
    @click="selectedPost = post"
  >
    <div
      v-if="
        post.platformDetails && post.platformDetails.length > 0
      "
      class="platform-logo relative"
    >
      <img
        :src="post.platformDetails[0].logo_url"
        :alt="post.platformDetails[0].label"
        class="w-6 h-6"
      />
    </div>
    <p v-if="!post.scheduled_at && post.created_at">
      {{ formatDatepost(post.created_at) }}
    </p>
    <p v-if="post.when_to_post != 'immediate' && post.scheduled_at">
      {{ formatTime(post.scheduled_at) }}
    </p>
  </div>
  <button
    v-if="posts[day.toISOString().split('T')[0]].length > 20"
    @click="toggleWeeklyDay(day)"
    class="mt-2 text-blue-500"
  >
    {{
      expandedWeeklyDay &&
      expandedWeeklyDay.toISOString() === day.toISOString()
        ? "Show Less"
        : "Show More"
    }}
  </button>
</div>

              </div>
            </div>
          </div>

          <div
            class="bg-gray-100 text-sm leading-6 text-gray-700 p-2 pb- rounded-b"
          >
            <!-- Desktop Layout -->

            <!-- Mobile Layout -->
            <div v-if="isMobile" class="w-full grid grid-cols-1 gap-2">
              <div
                class="flex-grow bg-gray-100 text-sm leading-6 text-gray-700"
              >
                <div
                  v-if="currentView === 'month'"
                  class="w-full grid grid-cols-7 gap-2"
                >
                  <div
                    v-for="day in monthDays"
                    :key="day.toISOString()"
                    class="relative p-2 bg-white rounded-lg shadow-sm"
                    :class="{
                      'bg-white': day.getMonth() === currentDate.getMonth(),
                      'bg-gray-50 text-gray-500':
                        day.getMonth() !== currentDate.getMonth(),
                      'border border-[#8050DE] rounded-lg': isToday(day),
                    }"
                  >
                    <div class="flex justify-between items-center">
                      <time
                        :datetime="day.toISOString()"
                        :class="{ 'text-blue-600 font-bold': isToday(day) }"
                        v-text="day.getDate()"
                        @click="handleDayClick(day)"
                      ></time>
                      <span
                        v-if="shouldShowDot(day)"
                        :class="dotClass(day)"
                        @click="openDayDetails(day)"
                        class="dot-indicator"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
  v-if="selectedPost"
  class="fixed inset-0 bg-black bg-opacity-50 flex  justify-center p-4"
>
  <div
    class="p-10 bg-slate-50 rounded-[32px] shadow max-w-4xl w-full max-h-[800px] overflow-y-auto relative post-card-container -ml-52 max-h-[600px]"
  >
    <!-- Close Button -->
    <v-btn
      icon
      color="black"
      @click="selectedPost = null"
      class="absolute top-1 right-3"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="flex flex-col md:flex-row">
      
      <!-- Left Side: Post Stats and Post Info -->
      <div
  v-if="selectedPost.when_to_post !== 'schedule'"
  class="md:w-full md:mr-6 flex flex-col space-y-4"
>
  <!-- Engagement Details section with updated UI -->
  <div class="h-[280px] p-6 bg-white rounded-2xl shadow flex-col justify-start items-start gap-8 inline-flex">
    <div class="text-black text-2xl font-bold font-['SF Pro Display'] leading-loose">
      Engagement Details
    </div>

    <div class="flex-col justify-start items-start gap-6 flex">
      <!-- Reactions Section -->
      <div class="w-[319px] justify-start items-start gap-4 inline-flex">
        <div class="justify-start items-center gap-2 ">
          <img src="@/assets/img/thumb_n.svg" class="cursor-pointer" />
        </div>
        <div class="w-[136px] text-black text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">
          Reactions
        </div>
        <div class="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
          <div class="w-[21px] text-center text-[#667790] text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">
            {{ selectedPost.likes !== undefined && selectedPost.likes !== null ? selectedPost.likes : '-' }}
          </div>
          <!-- <div class="px-1.5 py-1 bg-[#18d49d] rounded-[50px] justify-center items-center flex">
            <div class="w-4 h-4 relative"></div>
            <div class="w-7 text-white text-xs font-bold font-['Inter'] leading-none">70%</div>
          </div> -->
        </div>
      </div>

      <!-- Shares Section -->
      <div class="w-[319px] justify-start items-start gap-4 inline-flex">
        <div class="justify-start items-center gap-2">
          <img src="@/assets/img/share_n.svg" class="cursor-pointer" />
        </div>
        <div class="w-[136px] text-black text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">
          Shares
        </div>
        <div class="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
          <div class="w-[21px] text-center text-[#667790] text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">
            {{ selectedPost.shares !== undefined && selectedPost.shares !== null ? selectedPost.shares : '-' }}
          </div>
          <!-- <div class="px-1.5 py-1 bg-[#ff5757] rounded-[50px] justify-center items-center flex">
            <div class="w-4 h-4 relative"></div>
            <div class="w-[27px] text-white text-xs font-bold font-['Inter'] leading-none">19%</div>
          </div> -->
        </div>
      </div>

      <!-- Comments Section -->
      <div class="w-[319px] justify-start items-start gap-4 inline-flex">
        <div class="justify-start items-center gap-2 ">
          <img src="@/assets/img/comt_n.svg" class="cursor-pointer" />
        </div>
        <div class="w-[136px] text-black text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">
          Comments
        </div>
        <div class="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
          <div class="w-[21px] text-center text-[#667790] text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">
            {{ selectedPost.comment !== undefined && selectedPost.comment !== null ? selectedPost.comment : '-' }}
          </div>
          <!-- <div class="px-1.5 py-1 bg-[#ff5757] rounded-[50px] justify-center items-center flex">
            <div class="w-4 h-4 relative"></div>
            <div class="w-[27px] text-white text-xs font-bold font-['Inter'] leading-none">25%</div>
          </div> -->
        </div>
      </div>

      <!-- Clicks Section -->
      <!-- <div class="w-[319px] justify-start items-start gap-4 inline-flex">
        <div class="justify-start items-center gap-2 flex"></div>
        <div class="w-[136px] text-black text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">
          Clicks
        </div>
        <div class="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
          <div class="w-[21px] text-center text-[#667790] text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">
            1
          </div>
          <div class="px-1.5 py-1 bg-[#18d49d] rounded-[50px] justify-center items-center flex">
            <div class="w-4 h-4 relative"></div>
            <div class="w-[27px] text-white text-xs font-bold font-['Inter'] leading-none">17%</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <!-- Post Info section -->
<div class="h-[280px] p-6 bg-white rounded-2xl shadow flex-col justify-start items-start gap-8 inline-flex">
    <div class="text-black text-2xl font-bold font-['SF Pro Display'] leading-loose">Post Information</div>
    
    <div class="self-stretch h-[168px] flex-col justify-start items-start gap-6 flex">
        <!-- Published Date -->
        <div class="self-stretch justify-start items-start gap-4 inline-flex">
            <div class="w-6 h-6 relative">
              <img src="@/assets/img/time.svg" class="cursor-pointer" />
            </div>
            <div class="w-[136px] text-black text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">Published</div>
            <div class="grow shrink  h-6 justify-start items-center gap-3 flex">
                <div class="grow shrink  text-[#667790] text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">
                    {{ formatselectpost(selectedPost.created_at) }}
                </div>
            </div>
        </div>

        <!-- Post Link -->
        <div class="self-stretch justify-start items-start gap-4 inline-flex">
            <div class="w-6 h-6 relative">
              <img src="@/assets/img/link_n.svg" class="cursor-pointer" />
            </div>
            <div class="w-[136px] text-black text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">Post Link</div>
            <div class="grow shrink h-6 justify-start items-center gap-3 flex">
                <a 
                    :href="selectedPost.post_url" 
                    target="_blank" 
                    class="grow shrink  text-[#8056dd] text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight break-words whitespace-nowrap">
                    {{ getDomain(selectedPost.post_url) }}
                </a>
            </div>
        </div>

        <!-- Author -->
        <div class="self-stretch justify-start items-start gap-4 inline-flex" v-if="selectedPost.profile_name
">
            <div class="w-6 h-6 relative">
              <img src="@/assets/img/person_n.svg" class="cursor-pointer" />
            </div>
            <div class="w-[136px] text-black text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">Author</div>
            <div class="grow shrink  h-6 justify-start items-center gap-3 flex">
                <div class="grow shrink  text-[#667790] text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">
                    {{ selectedPost.profile_name
 }}
                </div>
            </div>
        </div>

        <!-- Tags -->
        <div class="self-stretch justify-start items-start gap-4 inline-flex" v-if="selectedPost.tags && selectedPost.tags.length">
            <div class="w-6 h-6 relative"></div>
            <div class="w-[136px] text-black text-[15px] font-semibold font-['Inter'] leading-normal tracking-tight">Tags</div>
            <div class="justify-start items-center gap-2 flex">
                <div v-for="tag in selectedPost.tags" :key="tag" :class="tagClass(tag)" class="px-2 rounded shadow-inner justify-center items-center gap-1 flex">
                    <div class="text-black text-[15px] font-semibold font-['Inter'] leading-normal">{{ tag }}</div>
                </div>
            </div>
        </div>
    </div>
</div>


</div>

      <div
        v-if="
          selectedPost.when_to_post === 'schedule' &&
          !isPostLive(selectedPost)
        "
        class="md:w-full mt-1 md:mt-0 pr-6 flex flex-col space-y-4 "
      >
        <div class="bg-white rounded-2xl shadow h-[200px] p-6">
          <p class="font-bold text-lg mb-4">Scheduled Post Link</p>
          <div class="grid gap-4">
            <div>
              <p class="font-medium">Scheduled For</p>
              <p>{{ formatselectpost(selectedPost.scheduled_at) }}</p>
            </div>
            <div>
              <p
                class="font-medium cursor-pointer text-blue-600"
                @click="openPostDetaild(selectedPost)"
              >
                Open Post<v-icon color="primary"
                  >mdi-arrow-top-right</v-icon
                >
              </p>
              <a
                :href="selectedPost.post_url"
                target="_blank"
                class="text-blue-500 break-all"
                >{{ selectedPost.post_url }}</a
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Right Side: Post Content -->
      <div class="md:w-full md:pl-6 w-[576px] h-full px-8 pt-4 pb-8 bg-[#fcfcfc] rounded-2xl shadow border-2 border-[#e6e8ec] backdrop-blur-[32px]">
  <div class="flex items-center justify-between mb-4">
    <div class="flex items-start relative">
  <div v-for="platform in selectedPost.platformDetails" :key="platform.name" class="flex items-center">
    <!-- Platform logo -->
    <div class="relative w-12 h-12 rounded-full -mt-2">
      <img :src="platform.logo_url" :alt="platform.label" class="w-10 h-10" />
    </div>
    <!-- Profile name -->
    <div class="text-sm font-medium mb-6 ">
      {{ selectedPost.profile_name }}
      <p class="text-[#667790] text-sm font-semibold font-['Inter']">{{ formatselectpost(selectedPost.created_at) }}</p>
    </div>
  </div>
  
  <!-- Three dot icon aligned to the far right -->
  <img  v-if="selectedPost.when_to_post !== 'schedule'" src="@/assets/img/three_dot.svg" class="absolute -right-8 top-0 cursor-pointer" />
</div>



  
    <div class="flex space-x-2 justify-end">
      <v-btn v-if="selectedPost.when_to_post === 'schedule' && !isPostLive(selectedPost)" icon @click="editPost(selectedPost.id)">
        <v-icon color="primary">mdi-pencil</v-icon>
      </v-btn>
      <v-btn v-if="selectedPost.scheduled_at && !isPostLive(selectedPost)" icon @click="deletePost(selectedPost)">
        <v-icon color="error">mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
  <div>  <h3 class="text-2xl font-bold">{{ selectedPost.title }}</h3></div>
  <p class="mb-4 text-black text-[15px] font-medium font-['Inter'] leading-normal inline">
  {{ truncatedText }}
  <button v-if="selectedPost.text.length > 200" @click="toggleTextExpansion" class="text-blue-500 text-xs inline ml-1">
    {{ isTextExpanded ? "Show Less" : "Show More" }}
  </button>
</p>

  <!-- Media rendering section -->
  <div v-if="selectedPost.media_type === 'video'">
    <iframe v-if="isYouTube(selectedPost.videos[0].src)" :src="getYoutubeEmbedUrl(selectedPost.videos[0].src)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="video-container"></iframe>
    <video v-else-if="selectedPost.videos[0].src" controls class="video-container mb-4">
      <source :src="selectedPost.videos[0].src" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <img v-else-if="selectedPost.videos[0].thumbnail" :src="selectedPost.videos[0].thumbnail" alt="Video thumbnail" class="w-full h-auto max-h-80 mb-4 object-contain rounded-3xl" />
  </div>

  <img v-else-if="selectedPost.images && selectedPost.images.length > 0" :src="selectedPost.images[0]" :alt="`Image for ${selectedPost.title}`" class="w-full h-auto max-h-80 rounded-md mb-4 mt-2 object-contain" />

  <!-- Show more video section -->
  <div v-if="selectedPost.when_to_post === 'schedule' && selectedPost.videos && selectedPost.videos.length > 0">
    <video v-for="(video, index) in selectedPost.videos" :key="index" controls class="w-full max-h-80 mb-4 mt-2 rounded-md">
      <source :src="video" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>

  <!-- <p class="mb-4 text-black text-[15px] font-medium font-['Inter'] leading-normal">{{ truncatedText }}</p>
  <button v-if="selectedPost.text.length > 200" @click="toggleTextExpansion" class="text-blue-500">
    {{ isTextExpanded ? "Show Less" : "Show More" }}
  </button> -->

  <!-- Interaction buttons section -->
  <div class="h-[52px] justify-between items-center flex w-full">
    <div class="justify-start items-center gap-2 flex">
      <div class="p-3.5 rounded-[40px] justify-center items-center gap-2.5 flex">
        <div class="w-6 h-6 relative">
          <img src="@/assets/img/thumbs_up.svg" class="cursor-pointer" />
        </div>
      </div>
      <div class="p-3.5 bg-[#f5f0ff] rounded-[40px] justify-center items-center gap-2.5 flex">
        <div class="w-6 h-6 relative">
          <img src="@/assets/img/msg.svg" class="cursor-pointer" />
        </div>
      </div>
      <div class="p-3.5 rounded-[40px] justify-center items-center gap-2.5 flex">
        <div class="w-6 h-6 relative">
          <img src="@/assets/img/share.svg" class="cursor-pointer" />
        </div>
      </div>
    </div>

    <div   v-if="selectedPost.when_to_post !== 'schedule'" class="h-12 px-2 py-3 bg-white rounded-lg border border-[#e6e8ec] justify-center items-center gap-2 inline-flex cursor-pointer" @click="openPostLink">
  <div class="text-black text-[15px] font-semibold font-['Inter'] leading-normal whitespace-nowrap">Open Post</div>
  <div class="w-4 h-4 relative"><img src="@/assets/img/open_post.svg" class="cursor-pointer" /></div>
</div>

  </div>
</div>

    </div>
  </div>
</div>


          <v-dialog v-model="scheduleDialog" persistent max-width="300px">
            <v-card>
              <v-card-title
                >Schedule for
                {{
                  selectedDate ? selectedDate.toDateString() : "Select Date"
                }}</v-card-title
              >
              <v-time-picker v-model="selectedTime"></v-time-picker>
              <v-card-actions>
                <v-btn color="primary" text @click="scheduleDialog = false"
                  >Close</v-btn
                >
                <v-btn color="primary" text @click="logSelectedDateTime"
                  >Submit</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="detailsDialog" max-width="400px">
            <v-card>
              <v-card-title class="text-xs md:text-md">
                <span class="text-xs md:text-md"
                  >Details for {{ selectedDayDetails.date }}</span
                >
              </v-card-title>
              <v-card-text>
                <div
                  v-if="selectedDayDetails.holidayName"
                  class="holiday-name text-black bg-pink-100 rounded-md px-2"
                >
                  {{ selectedDayDetails.holidayName }}
                </div>
                <div
                  v-for="post in selectedDayDetails.posts.filter(
                    (post) => post.post_status !== 'deleted'
                  )"
                  :key="post.id"
                  class="post-container flex justify-between mt-1 cursor-pointer p-2 bg-gray-50 rounded-md hover:bg-gray-200 transition duration-300"
                  @click="openPostDetails(post)"
                >
                  <p>{{ truncateName(post.name) }}</p>
                  <img
                    v-if="post.images && post.images.length > 0"
                    :src="post.images[0]"
                    :alt="`Image for ${post.name}`"
                    class="aspect-square w-[23px] rounded-md"
                  />
                </div>
                <div class="flex">
                  <div v-if="selectedDayDetails.canSchedule" class="mt-2 mr-1">
                    Schedule Post
                  </div>
                  <button
                    v-if="selectedDayDetails.canSchedule"
                    @click="schedule(selectedDayDetails.day)"
                    class="plusbtn text-lg text-blue-600 my-1"
                  >
                    +
                  </button>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn color="blue darken-1" text @click="detailsDialog = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <div v-if="!dismissed" class="onboarding-popup">
            <div
              @click="toggleOnboardingVisibility"
              class="flex items-center justify-between cursor-pointer"
            >
              <h2 class="font-bold">Onboarding Steps</h2>
              <v-icon
                :name="
                  isOnboardingVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'
                "
                color="white"
              ></v-icon>
              <v-icon v-if="isOnboardingVisible" color="white"
                >mdi-chevron-down</v-icon
              >
              <v-icon color="white" v-if="!isOnboardingVisible"
                >mdi-chevron-up</v-icon
              >
            </div>
            <div v-if="isOnboardingVisible">
              <div class="progress-text">
                {{ completedSteps }} of {{ totalSteps }} steps completed
              </div>
              <div class="progress-bar">
                <div
                  class="progress-bar-fill"
                  :style="{ width: progressPercentage + '%' }"
                ></div>
              </div>
              <div
                v-for="(item, index) in onboardingSteps"
                :key="index"
                class="checkbox-item flex justify-between"
              >
                <input
                  type="checkbox"
                  :id="`step-${index}`"
                  class="custom-checkbox"
                  v-model="item.completed"
                />
                <label :for="`step-${index}`">
                  <router-link
                    :to="item.route"
                    :style="{ color: 'white' }"
                    :class="{ strikethrough: item.completed }"
                    >{{ item.name }}</router-link
                  >
                </label>
                <router-link :to="item.route">
                  <v-icon color="white cursor-pointer"
                    >mdi-chevron-right</v-icon
                  >
                </router-link>
              </div>
              <div class="text-center bg-slate-800 mt-2 p-2 rounded-sm text-xs">
                <a
                  href="https://calendly.com/fly-social/30min"
                  target="_blank"
                  class="need-help-link"
                  >Need help? Connect with a member of our team</a
                >
              </div>
              <button @click="dismissOnboarding" class="dismiss-button text-sm">
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <v-dialog
      v-if="checkUID"
      v-model="initialDialog"
      persistent
      max-width="470px"
    >
      <v-card>
        <v-card-title class="headline">Thank you for signing up!</v-card-title>
        <v-card-text>
          To get started, we need to create a workspace for your brand.
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="openEditWorkspace"
            >Create Workspace</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="checkUID"
      v-model="afterDialog"
      persistent
      max-width="470px"
    >
      <v-card>
        <v-card-title class=" text-xs font-medium">Mandatory workspace details missing!</v-card-title>
        <v-card-text>
          We need to know more about your workspace before we can proceed
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="openWorkspace">Add Details</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="workspaceadd" persistent max-width="470px">
      <DialogWorkspaceAdd
        title="Add your workspace"
        @close="closeNameDialog"
        @submit="saveWorkspace"
      >
        <!-- Workspace Name Field -->
        <v-card class="cursor-pointer">
          <v-text-field
            v-model="workspaceName"
            label="Workspace Name"
            prepend-icon="mdi-briefcase"
            class="ml-2 cursor-pointer"
          ></v-text-field>
        </v-card>

        <!-- Website Field -->
        <v-card class="cursor-pointer mt-2">
          <v-text-field
            v-model="website"
            label="Website"
            prepend-icon="mdi-web"
            type="url"
            class="ml-2 cursor-pointer"
            :rules="[(v) => !!v || 'Website is required']"
          />
        </v-card>

        <!-- Business Description Field -->
        <v-card class="cursor-pointer mt-2">
          <v-textarea
            v-model="businessDescription"
            label="Business Description"
            prepend-icon="mdi-text"
            auto-grow
            :rules="[(v) => !!v || 'Business Description is required']"
          />
        </v-card>

        <!-- Brand Logo Field -->
        <v-card class="cursor-pointer mt-2 mb-2">
          <v-file-input
            v-model="brandLogo"
            label="Upload Brand Logo"
            prepend-icon="mdi-upload"
            accept="image/*"
            class="ml-2 cursor-pointer"
          ></v-file-input>
        </v-card>

        <!-- Country Field -->
        <v-card class="mt-2 p-2">
          <v-combobox
  v-model="countryCode"
  :items="countryOptions"
  label="Country"
  item-text="name"
  item-value="value"
  clearable
  return-object
  hide-selected
  dense
></v-combobox>

        </v-card>
        <v-card class="mt-2 p-2">
          <v-combobox
  v-model="timezone"
  :items="timezoneOptions"
  label="Timezone"
  item-text="label" 
  item-value="value" 
  prepend-icon="mdi-earth"
  clearable
  return-object
  hide-selected
  dense
  class="ml-2 cursor-pointer"
>
</v-combobox>
        </v-card>

        <!-- Primary Email Field -->
        <v-card class="cursor-pointer mt-2">
          <v-text-field
            v-model="primaryEmail"
            label="Primary Email"
            prepend-icon="mdi-email"
            type="email"
            class="ml-2 cursor-pointer"
            :rules="emailRules"
          ></v-text-field>
        </v-card>

        <!-- Weekly Report Checkboxes -->
        <v-checkbox
          v-model="sendWeeklyReport"
          label="Send weekly social media performance report to primary email"
        ></v-checkbox>
        <vue-tel-input-vuetify
          class="bg-[#f2f6fa] flex flex-row gap-3 outline-[#8056DE] md:w-[309px] h-14 rounded-[26px] mt-2 pb-3 mb-3"
          mode="international"
          v-model="phone_number"
          :rules="phoneRules"
          :validate-on-blur="true"
        >
          <input
            type="text"
            class="bg-[#f2f6fa] flex flex-row gap-3 w-full outline-[#8056DE] md:w-[309px] h-12 items-start rounded-[26px]"
            placeholder="Phone Number"
          />
        </vue-tel-input-vuetify>

        <v-checkbox
          v-model="sendWeeklyReportonphone"
          label="Send weekly social media performance report to primary phone number"
        ></v-checkbox>
      </DialogWorkspaceAdd>
    </v-dialog>
    <v-dialog v-model="nameDialog" persistent max-width="470px">
      <DialogWorkspaceAdd
        title="Add your workspace"
        @close="closeNameDialog"
        @submit="editWorkspace"
      >
        <!-- Workspace Name Field -->
        <v-card class="cursor-pointer">
          <v-text-field
            v-model="workspaceName"
            label="Workspace Name"
            prepend-icon="mdi-briefcase"
            class="ml-2 cursor-pointer"
          ></v-text-field>
        </v-card>

        <!-- Website Field -->
        <v-card class="cursor-pointer mt-2">
          <v-text-field
            v-model="website"
            label="Website"
            prepend-icon="mdi-web"
            type="url"
            class="ml-2 cursor-pointer"
          />
        </v-card>

        <!-- Business Description Field -->
        <v-card class="cursor-pointer mt-2">
          <v-textarea
            v-model="businessDescription"
            label="Business Description"
            prepend-icon="mdi-text"
            auto-grow
          />
        </v-card>

        <!-- Brand Logo Field -->
        <v-card class="cursor-pointer mt-2 mb-2">
          <v-file-input
            v-model="brandLogo"
            label="Upload Brand Logo"
            prepend-icon="mdi-upload"
            accept="image/*"
            class="ml-2 cursor-pointer"
          ></v-file-input>
        </v-card>

        <!-- Country Field -->
        <v-card class="mt-2 p-2">
          <v-combobox
  v-model="countryCode"
  :items="countryOptions"
  label="Country"
  item-text="name"
  item-value="value"
  clearable
  return-object
  hide-selected
  dense
></v-combobox>

        </v-card>
        <v-card class="mt-2 p-2">
          <v-combobox
            v-model="timezone"
            :items="timezoneOptions"
            label="Timezone"
            item-text="label"
            item-value="value"
            prepend-icon="mdi-earth"
            clearable
            return-object
            hide-selected
            dense
            class="ml-2 cursor-pointer"
          >
          </v-combobox>
        </v-card>

        <!-- Primary Email Field -->
        <v-card class="cursor-pointer mt-2">
          <v-text-field
            v-model="primaryEmail"
            label="Primary Email"
            prepend-icon="mdi-email"
            type="email"
            class="ml-2 cursor-pointer"
          ></v-text-field>
        </v-card>

        <!-- Weekly Report Checkboxes -->
        <v-checkbox
          v-model="sendWeeklyReport"
          label="Send weekly social media performance report to primary email"
        ></v-checkbox>
        <vue-tel-input-vuetify
          class="bg-[#f2f6fa] flex flex-row gap-3 outline-[#8056DE] md:w-[309px] h-14 rounded-[26px] mt-2 pb-3 mb-3"
          mode="international"
          v-model="phone_number"
        >
          <input
            type="text"
            class="bg-[#f2f6fa] flex flex-row gap-3 w-full outline-[#8056DE] md:w-[309px] h-12 items-start rounded-[26px]"
            placeholder="Phone Number"
          />
        </vue-tel-input-vuetify>

        <v-checkbox
          v-model="sendWeeklyReportonphone"
          label="Send weekly social media performance report to primary phone number"
        ></v-checkbox>
      </DialogWorkspaceAdd>
    </v-dialog>
  </div>
</template>

<script>
import {
  doc,
  getDocs,
  collection,
  updateDoc,
  setDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  auth,
  colUsers,
  db,
  functions,
  colScheduledPostsDelete,
  colSocialPostsDelete,
} from "@/utils/firebase.utils.js";
import moment from "moment";
import DialogWorkspaceAdd from "../../components/ui/DialogWorkspaceAdd.vue";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import countries from "i18n-iso-countries";
import _ from "lodash";
import { getDate, mapDocs } from "@/utils/common.utils";
export default {
  components: {
    DialogWorkspaceAdd,
  },
  data() {
    return {
      connectedAppsCount: 0,
      connectedAppsLoaded: false, 
      isLoading: false,
      isTextExpanded: false,
      isOnboardingVisible: true,
      dismissed: true,
      detailsDialog: false,
      selectedDayDetails: {
        day: null,
        date: "",
        holidayName: "",
        posts: [],
        canSchedule: false,
      },
      onboardingSteps: [
        // {
        //   name: "Complete  profile details",
        //   completed: false,
        //   route: "/edit-profile",
        //   key: "is_profile_completed",
        // },
        {
          name: "Connect your social handles",
          completed: false,
          route: "/connected-apps",
          key: "is_connected",
        },
        {
          name: "Publish your first post",
          completed: false,
          route: "/create",
          key: "is_posted",
        },
        {
          name: "Generate content ideas",
          completed: false,
          route: "/content-ai",
          key: "is_content_ai_generated",
        },
        // {
        //   name: "Generate content ideas using Fly",
        //   completed: false,
        //   route: "/create",
        //   key: "is_idea_generated",
        // },
      ],
      currentDate: new Date(),
      days: [],
      loadingStates: {},
      loading: true,
      posts: {},
      featurePosts: [],
      currentView: "month",
      expandedDay: null,
      selectedPost: null,
      scheduleDialog: false,
      selectedDate: null,
      selectedTime: null,
      sidebarOpen: false,
      sidebarPosts: [],
      selectedDay: null,
      holidays: [], // Time selected by the user
      isMobile: window.innerWidth <= 768,
      expandedWeeklyDay: null,
      initialDialog: false,
      afterDialog: false,
      nameDialog: false,
      workspaceadd: false,
      workspaceName: "",
      website: "",
      businessDescription: "",
      primaryEmail: "",
      emailRules: [
        (v) => !!v || "Email is required", // Check for non-empty input
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid", // Validate email format
      ],
      sendWeeklyReport: true,
      phone_number: "",
      phoneRules: [
        (v) => !!v || "Phone number is required", // Checks for non-empty input
        (v) => (v && v.length >= 10) || "Phone number must be valid", // Additional validation to ensure length
      ],
      sendWeeklyReportonphone: true,
      brandLogo: null,
      editableWorkspace: {},
      countryCode: "",
      countryOptions: null,
      timezones: moment.tz.names(),
      timezone: null, // This will hold the selected timezone
      timezoneOptions: this.getTimezoneOptions(),
      profileImages: {},
    };
  },
  computed: {
    checkWorkspaceRole(){
      if(this.currentWorkspace && this.currentWorkspace?.role==="Client") return false;
      return true;
    },
    checkUID() {
      if (auth.currentUser.uid == this.currentUID) {
        return true;
      }
      return false;
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
    monthDays() {
      const days = [];
      const year = this.currentDate.getUTCFullYear();
      const month = this.currentDate.getUTCMonth();

      const firstDay = new Date(Date.UTC(year, month, 1));
      const lastDay = new Date(Date.UTC(year, month + 1, 0));

      let startDay = new Date(
        Date.UTC(
          firstDay.getUTCFullYear(),
          firstDay.getUTCMonth(),
          firstDay.getUTCDate() - firstDay.getUTCDay()
        )
      );
      let endDay = new Date(
        Date.UTC(
          lastDay.getUTCFullYear(),
          lastDay.getUTCMonth(),
          lastDay.getUTCDate() + (6 - lastDay.getUTCDay())
        )
      );

      for (
        let day = new Date(startDay);
        day <= endDay;
        day.setUTCDate(day.getUTCDate() + 1)
      ) {
        days.push(new Date(day));
      }
      return days;
    },
    weekDays() {
      const days = [];
      const startOfWeek = new Date(
        Date.UTC(
          this.currentDate.getUTCFullYear(),
          this.currentDate.getUTCMonth(),
          this.currentDate.getUTCDate() - this.currentDate.getUTCDay()
        )
      );

      for (let i = 0; i < 7; i++) {
        const day = new Date(startOfWeek);
        day.setUTCDate(day.getUTCDate() + i);
        days.push(day);
      }
      return days;
    },
    completedSteps() {
      return this.onboardingSteps.filter((step) => step.completed).length;
    },
    totalSteps() {
      return this.onboardingSteps.length;
    },
    progressPercentage() {
      return (this.completedSteps / this.totalSteps) * 100;
    },
    truncatedText() {
      if (this.selectedPost && this.selectedPost.text) {
        if (this.isTextExpanded) {
          return this.selectedPost.text;
        } else {
          return this.selectedPost.text.length > 200
            ? this.selectedPost.text.substring(0, 200) + "..."
            : this.selectedPost.text;
        }
      }
      return "";
    },
  },
  async mounted() {

    window.addEventListener("resize", this.checkMobile);
    window.addEventListener("resize", () => {
      this.$forceUpdate(); // Re-render component on window resize to update the date format
    });
    document.body.classList.add("planner-page");

    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    this.fetchPostsByDateRange(firstDayOfMonth, lastDayOfMonth);

    this.apiCall(); // Fetch posts when the component mounts
    this.initializeOnboardingSteps();
    this.fetchOnboardingSteps();
    await this.checkConnectedApps(); // Fetch connected apps
    this.connectedAppsLoaded = true;
    if (localStorage.getItem("isNewUser") === "true") {
      this.initialDialog = true;
      localStorage.removeItem("isNewUser"); // Remove the flag after showing the dialog
    } 
    this.initCountries();
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.$forceUpdate(); // Clean up the event listener
    });
    window.removeEventListener("resize", this.checkMobile);
    document.body.classList.remove("planner-page");
  },
  watch: {
    countryCode(newCountryCode) {
    if (newCountryCode) {
      this.updateTimezonesForCountry(newCountryCode); // Update timezones based on country selection
    }
  },
    selectedPost(newPost, oldPost) {
      if (newPost) {
     
      }
    },
    onboardingSteps: {
      handler(newSteps) {
        this.updateOnboardingStepsInFirestore(newSteps);
      },
      deep: true,
    },
  },
  methods: {
    getDomain(url) {
    try {
      const newUrl = new URL(url);
      return `${newUrl.protocol}//${newUrl.hostname}`;
    } catch (e) {
      return url; // If URL parsing fails, return the original URL
    }
  },
    openPostLink() {
    if (this.selectedPost && this.selectedPost.post_url) {
      window.open(this.selectedPost.post_url, "_blank"); // Opens the post link in a new tab
    } else {
      this.$snackbar.show("Post URL is not available"); // Handle case where URL is missing
    }
  },
    goToPreviousDay() {
      const prevDay = new Date(this.selectedDay);
      prevDay.setDate(this.selectedDay.getDate() - 1);
      this.selectedDay = prevDay;
    },
    
    // Method to go to the next day
    goToNextDay() {
      const nextDay = new Date(this.selectedDay);
      nextDay.setDate(this.selectedDay.getDate() + 1);
      this.selectedDay = nextDay;
    },
    async getConnected() {
      let vm = this;
      let q = query(
        collection(
          doc(colUsers, this.currentUID, "fly_workspaces", vm.WID),
          "connected-apps"
        )
      );
      let connectedApps = (await getDocs(q)).docs.map((i) => i.id);
      return connectedApps;
    },
    async checkConnectedApps() {
      const connectedApps = await this.getConnected();
      this.connectedAppsCount = connectedApps.length; // Update the count of connected apps
    },
    navigateToConnectedApps() {
      this.$router.push({ name: "connected-apps" });
    },
    openPostDetaild(post) {
      let uid = post.uid || this.$route.params.uid;
      let wid = post.wid || this.$route.params.wid;
      let id = post.id || this.$route.params.id;
      this.$router.push({
        name: "post",
        params: {
          uid: uid,
          wid: wid,
          id: id,
        },
      });
    },
    getTimezoneOptions() {
      return moment.tz.names().map((tz) => {
        const tzOffset = moment.tz(tz).utcOffset(); // Get timezone offset in minutes
        const offsetHours = Math.floor(Math.abs(tzOffset) / 60);
        const offsetMinutes = Math.abs(tzOffset) % 60;
        const offsetSign = tzOffset >= 0 ? "+" : "-";
        const formattedOffset = `${offsetSign}${offsetHours
          .toString()
          .padStart(2, "0")}:${offsetMinutes.toString().padStart(2, "0")}`; // Format as +hh:mm or -hh:mm

        return {
          label: `(UTC ${formattedOffset}) ${tz}`,
          value: tz,
        };
      });
    },

    initCountries() {
  countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

  // Create a country options array with full names and codes
  this.countryOptions = Object.entries(countries.getNames("en")).map(
    ([code, name]) => ({
      name: name,
      value: code,
    })
  );
},
updateTimezonesForCountry(country) {
    if (country && country.value) {
      // Get the timezones based on the selected country code
      const timezones = moment.tz.zonesForCountry(country.value.toUpperCase());
      this.timezoneOptions = timezones.map(zone => ({
        label: `(UTC ${moment.tz(zone).format('Z')}) ${zone}`,  // Format timezone label
        value: zone   // The actual value bound to v-model
      }));

      // If only one timezone is available, auto-select it
      if (this.timezoneOptions.length === 1) {
        this.timezone = this.timezoneOptions[0];  // Bind the entire object with label and value
      } else {
        this.timezone = null;  // Clear the timezone if there are multiple options
      }
    } else {
      // If no country is selected, show all available timezones
      const allTimezones = moment.tz.names();
      this.timezoneOptions = allTimezones.map(zone => ({
        label: `(UTC ${moment.tz(zone).format('Z')}) ${zone}`,  // Format timezone label
        value: zone   // The actual value bound to v-model
      }));

      this.timezone = null; // Clear selected timezone when country is deselected
    }
  },
getCountryObject(countryCode) {
  // Find and return the country object (name and code) based on the code
  return this.countryOptions.find((country) => country.value === countryCode);
},

    openEditWorkspace() {
      this.initialDialog = false; // Close the initial pop-up
      this.populateWorkspaceFields();
      this.nameDialog = true; // Open the Edit Workspace dialog
    },
    openWorkspace() {
      this.afterDialog = false; // Close the initial pop-up
      this.populateWorkspaceFields();
      this.workspaceadd = true; // Open the Edit Workspace dialog
    },
    showDialogAfterSignUp() {
      this.initialDialog = true;
      localStorage.removeItem("isNewUser"); // Clear the flag after showing the dialog
    },
    closeNameDialog() {
      this.nameDialog = false;
      this.clearEditFields();
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleLogoChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.brandLogo = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.editableWorkspace.logo_original = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    isYouTube(url) {
      return url.match(/youtube\.com|youtu\.be/);
    },
    getYoutubeEmbedUrl(url) {
      let id = "";
      if (url.includes("youtu.be")) {
        id = url.split("/").pop();
      } else if (url.includes("youtube.com/shorts/")) {
        id = url.split("/shorts/")[1];
      } else if (url.includes("youtube.com")) {
        const urlParams = new URLSearchParams(new URL(url).search);
        id = urlParams.get("v");
      }
      return `https://www.youtube.com/embed/${id}?playsinline=1`; // Adding playsinline parameter for proper mobile behavior
    },
    handleVideoError(event) {
      console.error("Video loading error:", event);
    },
    async populateWorkspaceFields() {
      try {
        // Assuming vm.uid is the user's UID
        const userRef = doc(db, "users", auth.currentUser.uid);
        const userDoc = await getDoc(userRef);
        console.log("userrr", userDoc);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("SDS", userData);
          // Check if user document has the fields 'primaryEmail' and 'phoneNumber'
          this.primaryEmail = userData.email || "";
          console.log("emu", userData.email);
          this.phone_number = userData.phone_number || "";

          const workspaceRef = doc(
            db,
            "users",
            auth.currentUser.uid,
            "fly_workspaces",
            this.WID || "default"
          );
          const workspaceDoc = await getDoc(workspaceRef);

          if (workspaceDoc.exists()) {
            const workspaceData = workspaceDoc.data();

            // Populate the fields with data from Firestore
            this.workspaceName = workspaceData.name || "";
            this.website = workspaceData.websiteLink || "";
            this.businessDescription = workspaceData.businessDescription || "";
            this.countryCode = workspaceData.countryCode || "";
            this.timezone = workspaceData.timezone || "";
            this.sendWeeklyReport =
              workspaceData.on_primary_email_send || false;
            this.sendWeeklyReportonphone =
              workspaceData.on_phone_number_send || false;
        // Country Code
     // Country Code
     const countryCode = workspaceData.countryCode || userData.country_code || "IN"; // Default to "IN" if not found
        this.countryCode = this.getCountryObject(countryCode);
            // Use user data if not defined in the workspace
            this.primaryEmail =
              this.primaryEmail || workspaceData.primary_email || "";
            this.phone_number =
              this.phone_number || workspaceData.phone_number || "";
          } else {
            console.log("Default workspace does not exist.");
          }
          // Open the dialog
          
        } else {
          console.log("No user data found.");
       
        }
      } catch (error) {
        console.error("Error fetching workspace/user data: ", error);
        this.$snackbar.show(
          "Failed to fetch workspace/user data. Please try again."
        );
      }
    },
    async checkDefaultWorkspace() {
      try {
        const workspaceRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "fly_workspaces",
          this.WID || "default"
        );
        const workspaceDoc = await getDoc(workspaceRef);

        if (workspaceDoc.exists()) {
          const workspaceData = workspaceDoc.data();

          // Check if the websiteLink and businessDescription are missing
          if (
            !workspaceData.websiteLink ||
            !workspaceData.businessDescription
          ) {
            this.afterDialog = true; // Show the dialog if any of the fields are missing
          }
        } else {
          console.log("Default workspace does not exist.");
          this.afterDialog = true; // Show the dialog if the workspace does not exist
        }
      } catch (error) {
        console.error("Error fetching default workspace: ", error);
        this.$snackbar.show(
          "Failed to fetch default workspace data. Please try again."
        );
      }
    },
    async editWorkspace() {
      this.$loader.show(`Creating the workspace`);
      try {
        const workspaceRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "fly_workspaces",
          this.WID || "default"
        );
        let logoURL = this.editableWorkspace.logo_original;

        const updateData = {};

        if (this.brandLogo && typeof this.brandLogo !== "string") {
          const storageRef = getStorage();
          const logoRef = ref(
            storageRef,
            `logos/${auth.currentUser.uid}/${this.brandLogo.name}`
          );
          const snapshot = await uploadBytes(logoRef, this.brandLogo);
          logoURL = await getDownloadURL(snapshot.ref);
          updateData.logo_original = logoURL;
        }

        if (this.workspaceName) {
          updateData.name = this.workspaceName;
          updateData.brandName = this.workspaceName;
        }
        if (this.website) updateData.websiteLink = this.website;
        if (this.businessDescription)
          updateData.businessDescription = this.businessDescription;
        if (this.primaryEmail) updateData.primary_email = this.primaryEmail;
        if (typeof this.sendWeeklyReport === "boolean")
          updateData.on_primary_email_send = this.sendWeeklyReport;
        if (this.phone_number) updateData.phone_number = this.phone_number;
        if (typeof this.sendWeeklyReportonphone === "boolean")
          updateData.on_phone_number_send = this.sendWeeklyReportonphone;
        if (this.countryCode) updateData.countryCode = this.countryCode;
        if (this.timezone) updateData.timezone = this.timezone;
        let payload = {
          uid: this.currentUID,
          wid: this.WID || "default",
          url: this.website,
          description: this.businessDescription,
        };
        if(this.businessDescription){
            const generateAiContent = httpsCallable(functions,"generateAiContentFunction");
          generateAiContent(payload);
         }
        if (Object.keys(updateData).length > 0) {
          await updateDoc(workspaceRef, updateData);
          this.$snackbar.show("Workspace Added successfully");
        }
        this.$store.commit("setBrandName", this.workspaceName);
        this.nameDialog = false;
        this.clearEditFields();
        // window.location.reload();
      } catch (error) {
        console.error("Error updating workspace: ", error);
        this.$snackbar.show("Failed to update workspace. Please try again.");
      }
      this.$loader.hide();
    },
    async saveWorkspace() {
      this.$loader.show(`Creating the workspace`);
      try {
        const workspaceRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "fly_workspaces",
          this.WID || "default"
        );
        let logoURL = this.brandLogo;

        const updateData = {};

        if (this.brandLogo && typeof this.brandLogo !== "string") {
          const storageRef = getStorage();
          const logoRef = ref(
            storageRef,
            `logos/${auth.currentUser.uid}/${this.brandLogo.name}`
          );
          const snapshot = await uploadBytes(logoRef, this.brandLogo);
          logoURL = await getDownloadURL(snapshot.ref);
          updateData.logo_original = logoURL;
        }

        if (this.workspaceName) updateData.name = this.workspaceName;
        if (this.website) updateData.websiteLink = this.website;
        if (this.businessDescription)
          updateData.businessDescription = this.businessDescription;
        if (this.primaryEmail) updateData.primary_email = this.primaryEmail;
        if (typeof this.sendWeeklyReport === "boolean")
          updateData.on_primary_email_send = this.sendWeeklyReport;
        if (this.phone_number) updateData.phone_number = this.phone_number;
        if (typeof this.sendWeeklyReportonphone === "boolean")
          updateData.on_phone_number_send = this.sendWeeklyReportonphone;
        if (this.countryCode) updateData.countryCode = this.countryCode;
        if (this.timezone) updateData.timezone = this.timezone;

        if (Object.keys(updateData).length > 0) {
          await updateDoc(workspaceRef, updateData);
          this.$snackbar.show("Workspace updated successfully");
        }
        let payload = {
          uid: this.currentUID,
          wid: this.WID || "default",
          url: this.website,
          description: this.businessDescription,
        };
        if(this.businessDescription){
            const generateAiContent = httpsCallable(functions,"generateAiContentFunction");
          generateAiContent(payload);
         }
        this.workspaceadd = false;
        this.clearEditFields();
        // window.location.reload();
      } catch (error) {
        console.error("Error updating workspace: ", error);
        this.$snackbar.show("Failed to update workspace. Please try again.");
      }
      this.$loader.hide();
    },

    // Clears the fields after saving
    clearEditFields() {
      this.workspaceName = "";
      this.website = "";
      this.businessDescription = "";
      this.primaryEmail = "";
      this.sendWeeklyReport = false;
      this.phone_number = "";
      this.sendWeeklyReportonphone = false;
      this.brandLogo = null;
    },
    getProfileImageUrl(post) {
      if (
        post.profile_image_url &&
        typeof post.profile_image_url === "string"
      ) {
        return post.profile_image_url;
      } else {
        return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi1p8_WaFcY_wt5qn3Vd0_VpkTtyo9AFeSB0PT3iVz1A&s";
      }
    },
    handleImageError(event) {
      event.target.src =
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi1p8_WaFcY_wt5qn3Vd0_VpkTtyo9AFeSB0PT3iVz1A&s";
    },
    formatDay(day) {
      if (day.getDate() === 1) {
        return `1 ${day.toLocaleString("default", { month: "short" })}`;
      }
      return day.getDate();
    },
    isDatePassed(day) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset hours to compare dates only
      return day < today;
    },
    toggleTextExpansion() {
      this.isTextExpanded = !this.isTextExpanded;
    },
    formatselectpost(dateInput) {
      // Define options for displaying the date in "August 6, 2024 11:45" format
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // Use 24-hour format if desired, or remove for 12-hour format
      };

      let date;

      // Check if input is a Firestore Timestamp object
      if (
        dateInput &&
        typeof dateInput === "object" &&
        dateInput.seconds !== undefined
      ) {
        // Convert Firestore Timestamp to JavaScript Date object
        date = new Date(
          dateInput.seconds * 1000 + dateInput.nanoseconds / 1000000
        );
        console.log("Converted Firestore Timestamp to Date:", date);
      } else if (typeof dateInput === "string") {
        // Handle string input as before
        if (dateInput.includes("T")) {
          date = new Date(dateInput);
        } else {
          const regex =
            /^(\d+)\s(\w+)\s(\d+)\sat\s(\d+):(\d+):(\d+)\sUTC([+-]\d+:\d+)$/;
          const parts = dateInput.match(regex);
          if (parts) {
            const [_, day, month, year, hour, minute, second, timezone] = parts;
            date = new Date(
              `${year}-${month}-${day}T${hour}:${minute}:${second}${timezone}`
            );
          } else {
            console.error(
              "Date format does not match expected patterns:",
              dateInput
            );
          }
        }
      } else {
        console.error("Invalid or missing date input:", dateInput);
        return "Invalid date format";
      }

      // Format and return the date if valid
      if (date && !isNaN(date.getTime())) {
        return date.toLocaleDateString("en-US", options);
      } else {
        console.error("Failed to parse date, resulting date is invalid:", date);
        return "Invalid date";
      }
    },

    closeSidebar() {
      this.sidebarOpen = false;
    },
    truncateText(text, maxLength) {
      return text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    formatDatepost(date) {
      if (!date) return "Date not provided";

      try {
        let parsedDate;

        // If date is a Firestore Timestamp object, convert it to a JS Date object
        if (
          typeof date === "object" &&
          date.seconds !== undefined &&
          date.nanoseconds !== undefined
        ) {
          date = new Date(
            date.seconds * 1000 + date.nanoseconds / 1000000
          ).toISOString();
        }

        // Check if the date is in ISO format
        if (
          typeof date === "string" &&
          date.includes("T") &&
          date.includes("Z")
        ) {
          parsedDate = moment(date); // ISO string is directly parsable by moment
        } else if (typeof date === "string") {
          // Preprocess the custom date format to convert it into a parsable format
          const customFormatRegex =
            /(\d{1,2} \w+ \d{4} at \d{2}:\d{2}:\d{2}) UTC([+-]\d{1,2}:\d{2})/;
          const match = date.match(customFormatRegex);
          if (match) {
            const dateTimePart = match[1]; // "26 July 2024 at 12:18:34"
            const timeZonePart = match[2]; // "+5:30" or "-5:30"
            const processedDate = `${dateTimePart}${timeZonePart}`;
            parsedDate = moment(processedDate, "D MMMM YYYY at HH:mm:ssZ");
          } else {
            throw new Error("Invalid date format");
          }
        } else {
          throw new Error("Invalid date type");
        }

        if (!parsedDate.isValid()) throw new Error("Invalid date");

        // Format the time to "HH:mm"
        return parsedDate.format("HH:mm");
      } catch (error) {
        console.error("Error formatting date:", date, error);
        return "Invalid date";
      }
    },
    formatTimesidebar(datetime) {
    const date = new Date(datetime);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${hours}:${minutes} ${ampm}`;
  },
    formatTime(dateInput) {
      let date;

      // Check if input is a Firestore Timestamp object
      if (
        dateInput &&
        typeof dateInput === "object" &&
        dateInput.seconds !== undefined
      ) {
        // Convert Firestore Timestamp to JavaScript Date object
        date = new Date(
          dateInput.seconds * 1000 + dateInput.nanoseconds / 1000000
        );
      } else if (typeof dateInput === "string") {
        // Handle string input
        if (dateInput.includes("T")) {
          date = new Date(dateInput);
        } else {
          const regex =
            /^(\d+)\s(\w+)\s(\d+)\sat\s(\d+):(\d+):(\d+)\sUTC([+-]\d+:\d+)$/;
          const parts = dateInput.match(regex);
          if (parts) {
            const [_, day, month, year, hour, minute, second, timezone] = parts;
            date = new Date(
              `${year}-${month}-${day}T${hour}:${minute}:${second}${timezone}`
            );
          } else {
            console.error(
              "Date format does not match expected patterns:",
              dateInput
            );
          }
        }
      } else {
        console.error("Invalid or missing date input:", dateInput);
        return "Invalid date format";
      }

      // Format and return the time if valid
      if (date && !isNaN(date.getTime())) {
        return date.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
      } else {
        console.error("Failed to parse date, resulting date is invalid:", date);
        return "Invalid date";
      }
    },
    handleDayClick(day) {
      if (this.isTodayOrAfter(day)) {
        this.schedule(day); // Perform scheduling action
      }
    },
    shouldShowDot(day) {
      return (
        this.hasActivePosts(day.toISOString().split("T")[0]) ||
        this.getHolidayName(day)
      );
    },
    dotClass(day) {
      const dateStr = day.toISOString().split("T")[0];
      if (this.hasActivePosts(dateStr)) {
        return "dot-blue";
      } else if (this.getHolidayName(day)) {
        return "dot-orange";
      }
      return "";
    },
    hasActivePosts(dateStr) {
      // Check if there are any non-deleted posts for the given date string
      const postsForDay = this.posts[dateStr] || [];
      return postsForDay.some((post) => post.post_status !== "deleted");
    },
    openDayDetails(day) {
      this.selectedDayDetails.day = day;
      this.selectedDayDetails.date = moment(day).format("MMMM Do, YYYY");
      this.selectedDayDetails.holidayName = this.getHolidayName(day);
      this.selectedDayDetails.posts =
        this.posts[day.toISOString().split("T")[0]] || [];
      this.selectedDayDetails.canSchedule = this.isTodayOrAfter(day);
      this.detailsDialog = true;
    },
    openPostDetails(post) {
      this.selectedPost = post;
      this.detailsDialog = false; // Close the details dialog when opening the post
    },
    isTodayOrAfter(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset hours to compare dates only
      return date >= today;
    },
    schedule(day) {
      console.log("Scheduling for:", day); // Implement your scheduling logic or trigger a modal for scheduling
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    editPost(postId) {
      let id = postId ? postId : this.$route.params.id;
      this.$router.push({
        name: "edit-post",
        params: {
          postId: id,
        },
      });
    },
    isPostLive(post) {
      console.log("inside");
      if (!post.scheduled_at) return false; // Return false if scheduled_at is not present

      // Convert Firestore Timestamp to JavaScript Date
      let scheduledDate;
      if (
        post.scheduled_at.seconds !== undefined &&
        post.scheduled_at.nanoseconds !== undefined
      ) {
        scheduledDate = new Date(post.scheduled_at.seconds * 1000);
      } else {
        scheduledDate = new Date(post.scheduled_at);
      }

      // if (isNaN(scheduledDate.getTime())) {
      //   console.error("Invalid date format for scheduled_at:", post.scheduled_at);
      //   return false; // Return false if the date is invalid
      // }

      const currentDate = new Date();
      // console.log("scheduledDate.getTime() < currentDate.getTime():", scheduledDate, scheduledDate.getTime(), scheduledDate.getTime() < currentDate.getTime());

      return scheduledDate.getTime() < currentDate.getTime();
    },
    async deletePost(post) {
      let postId = post.id ? post.id : this.$route.params.id;
      let data = {
        uid: post.uid,
        wid: post.wid,
        postId: postId,
        deleted_at: new Date(),
      };
      try {
        this.$confirm.show({
          message: `Are you sure you want to delete this post?`,
          onConfirm: async () => {
            const postRef = doc(
              colUsers,
              post.uid,
              "fly_workspaces",
              post.wid,
              "social-posts",
              postId
            );

            // Convert Firestore Timestamp to JavaScript Date
            let scheduledDate;
            if (
              post.scheduled_at.seconds !== undefined &&
              post.scheduled_at.nanoseconds !== undefined
            ) {
              scheduledDate = new Date(post.scheduled_at.seconds * 1000);
              scheduledDate = moment(scheduledDate).format("YYYY-MM-DD");
            } else {
              scheduledDate = new Date(post.scheduled_at);
              scheduledDate = moment(scheduledDate).format("YYYY-MM-DD");
            }

            //   if (isNaN(scheduledDate.getTime())) {
            //     console.error("Invalid date format for scheduled_at:", post.scheduled_at);
            //     this.$alert.show("Invalid scheduled date");
            //     return;
            //   }
            console.log("SJ", scheduledDate);

            const calendarPostRef = doc(
              colUsers,
              post.uid,
              "fly_workspaces",
              post.wid,
              "calendar",
              scheduledDate,
              "posts",
              postId
            );

            let isPostPublished = this.isPostLive(post);
            try {
              if (post.post_status === "draft") {
                this.$loader.show(`Deletion in progress`);
                await updateDoc(postRef, {
                  post_status: "deleted",
                });
                await updateDoc(calendarPostRef, {
                  post_status: "deleted",
                });
                this.$snackbar.show("Post deleted successfully!");
                this.$emit("onPostDelete");
                this.$loader.hide();
              } else if (post.scheduled_at && !isPostPublished) {
                data.was_scheduled_for = scheduledDate;
                data.action = "delete";
                this.$snackbar.show(
                  "Your scheduled posts have been marked for deletion."
                );
                await updateDoc(postRef, {
                  post_status: "deleted",
                });
                await updateDoc(calendarPostRef, {
                  post_status: "deleted",
                });
                await setDoc(doc(colScheduledPostsDelete, postId), data);
              } else {
                this.$snackbar.show(
                  "Your published posts have been marked for deletion."
                );
                await updateDoc(postRef, {
                  post_status: "deleted",
                });
                await updateDoc(calendarPostRef, {
                  post_status: "deleted",
                });
                await setDoc(doc(colSocialPostsDelete, postId), data);
              }
              const dateStr = scheduledDate;
              const updatedPosts = this.posts[dateStr].filter(
                (p) => p.id !== postId
              );
              this.$set(this.posts, dateStr, [...updatedPosts]);
              this.selectedPost = null;
            } catch (error) {
              this.$loader.hide();
              console.error("Error calling Cloud Function:", error);
              this.$alert.show("Something went wrong");
            }
          },
        });
      } catch (error) {
        console.error("Error deleting post:", error);
      }
    },
    truncateName(name) {
      return name.length > 20 ? name.substring(0, 20) + "..." : name;
    },
    truncateText(text, maxLength) {
      return text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    async apiCall() {
      const axios = require("axios");
      const currentYear = new Date().getFullYear(); // Get the current year dynamically

      try {
        const response = await axios.get(
          `https://holidays.abstractapi.com/v1/?api_key=188459ed78f24764b46365877c70ee5c&country=${this.authUser.country_code}&year=${currentYear}`
        );
        this.holidays = response.data; // Store the holiday data in the component's data
        console.log(this.holidays);
      } catch (error) {
        console.log(error);
      }
    },
    getHolidayName(date) {
      const dateString = moment(date).format("MM/DD/YYYY");
      const holiday = this.holidays.find(
        (holiday) => holiday.date === dateString
      );
      return holiday ? holiday.name : null;
    },
    logSelectedDateTime() {
      let type = ["social"];
      this.$store.commit("SET_FLY_PLATFORMS_TYPE", type);
      // Assuming this.selectedTime contains the time in the format "HH:mm"
      const timeParts = this.selectedTime.split(":");
      const hours = parseInt(timeParts[0]);
      const minutes = parseInt(timeParts[1]);

      // Combine date and time using moment.js
      const combinedDateTime = moment(this.selectedDate).set({
        hour: hours,
        minute: minutes,
      });
        if (combinedDateTime.isBefore(moment())) {
    this.$alert.show('Selected date and time is in the past')
    return;
  }

      // Format the combined date and time
      const formattedDateTime = combinedDateTime.format(
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );

      console.log(`Selected Date and Time: ${formattedDateTime}`);
      // console.log(`Selected Date and Time: ${this.selectedDate}, ${this.selectedTime}`);
      this.$router.push({
        name: "create-post",
        params: {
          scheduledTime: formattedDateTime,
        },
      });
    },
    schedule(date) {
      this.selectedDate = date;
      this.scheduleDialog = true;
    },
    displayedPosts(day) {
      const dateStr = day.toISOString().split("T")[0];
      const postsForDay = this.posts[dateStr] || [];
      const hasHoliday = this.getHolidayName(day);

      if (
        this.expandedDay &&
        this.expandedDay.toISOString() === day.toISOString()
      ) {
        return postsForDay; // Return all posts if the day is expanded
      }

      if (hasHoliday && postsForDay.length > 1) {
        return postsForDay.slice(0, 1); // Show only one post if there's a holiday and more than one post
      }

      return postsForDay.slice(0, 2); // Show up to two posts otherwise
    },

    toggleDay(day) {
      if (
        this.expandedDay &&
        this.expandedDay.toISOString() === day.toISOString()
      ) {
        this.expandedDay = null; // Collapse the day if it is already expanded
      } else {
        this.expandedDay = day; // Expand the selected day
      }
    },
    async fetchAllPlatforms() {
      const platformsRef = collection(db, "fly_platforms"); // Adjust the collection path if necessary
      const snapshot = await getDocs(platformsRef);
      let platforms = [];
      snapshot?.forEach((doc) => {
        platforms.push({ id: doc.id, ...doc.data() });
      });
      console.log(platforms,"platform")
      return platforms;
    },
    getCurrentDate() {
      return moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    },
    async fetchFeaturePosts(startDate, endDate) {
      // console.log("isFuture", endDate, moment().isBefore(moment(endDate)));
      let currentDate = this.getCurrentDate();
      let isFuture = moment().isBefore(moment(endDate));
      this.featurePosts = [];
      if (!isFuture) return;
      let isSameMonth =
        moment(startDate).format("MM-YYYY") == moment().format("MM-YYYY");
      // startDate = moment(startDate).startOf("day").toDate();
      endDate = moment(endDate).endOf("day").toDate();
      if (isSameMonth) {
        startDate = moment().startOf("day").toDate();
      }
      const postCollection = collection(
        db,
        "users",
        this.currentUID,
        "fly_workspaces",
        this.WID,
        "social-posts"
      );
      let scheduledListQuery = query(
        postCollection,
        where("scheduled_at", ">=", currentDate),
        where("scheduled_at", "<=", getDate(endDate)),
        where("when_to_post", "==", "schedule")
      );

      this.featurePosts = mapDocs((await getDocs(scheduledListQuery)).docs);
    },
    async fetchPostsByDateRange(startDate, endDate) {
      const fetchSocialPosts = httpsCallable(functions, "fetchSocialPosts"); // Define the callable function
      const payload = {
        uid: this.$store.getters.currentUID, // Get the user ID from Vuex store
        wid: this.WID, // Workspace ID from your component data or Vuex store
        operation: "fetchDateRangePosts",
        startDate: moment(startDate).startOf("day").format("YYYY-MM-DD"), // Ensures the whole day is covered
        endDate: moment(endDate).endOf("day").format("YYYY-MM-DD"), // Ensures the whole day is covered
      };

      console.log("payload month", payload);

      try {
        this.loading = true;
        this.posts = {};
        const response = await fetchSocialPosts(payload);
        // console.log("Response from fetchSocialPosts:", response);
        const data = response.data.result;
        console.log("Data received:", data);
        const allPlatforms = await this.fetchAllPlatforms(); // Fetch platform data once
        await this.fetchProfileImages(data);
        await this.fetchFeaturePosts(startDate, endDate);
        if (Array.isArray(data)) {
          for (const post of data) {
            post.when_to_post = "immediate"; // Add when_to_post field to each post
            let event = this.getPostEvent({ post, allPlatforms });
            if (!this.posts[event.dateStr]) {
              this.$set(this.posts, event.dateStr, []); // Initialize array if not present
            }
            this.posts[event.dateStr].push(event); // Append the post to the corresponding date array
          }
        } else {
          console.error("Data is not an array:", data);
        }
        for (const post of this.featurePosts) {
          post.social_media?.forEach((platform) => {
            let event = this.getPostEvent({
              post: {
                ...post,
                platform,
              },
              allPlatforms,
            });
            if (!this.posts[event.dateStr]) {
              this.$set(this.posts, event.dateStr, []); // Initialize array if not present
            }
            this.posts[event.dateStr].push(event); // Append the post to the corresponding date array
          });
        }
        this.loading = false;
      } catch (error) {
        this.handleError(error);
        this.loading = false;
      }
    },
    getPostEvent({ post, allPlatforms }) {
      let dateStr = post.created_time || post.created_at;
      if (post.when_to_post == "schedule") {
        dateStr = post.scheduled_at;
      }

      // Convert dateStr to 'YYYY-MM-DD' format if it is not already in that format
      if (dateStr) {
        dateStr = moment(dateStr).format("YYYY-MM-DD");
      }
      const platform =
        allPlatforms.find((p) => p.alias === post.platform) || {};
      post.platformDetails = [
        {
          name: post.platform,
          label: platform.name || "Unknown",
          logo_url: platform.logo_url || "",
        },
      ];
      // Ensure the profile image URL exists
      post.profile_image_url = _.get(
        this.profileImages,
        `${post.platform}.${post.profile_id}`,
        ""
      );
      return {
        ...post,
        dateStr,
      };
    },
    async fetchProfileImages(data) {
      let payloads = [];
      data?.forEach((post) => {
        let profileImage = _.get(
          this.profileImages,
          `${post.platform}.${post.profile_id}`
        );
        if (profileImage == undefined) {
          let item = payloads.find(
            (i) => i.platform == post.platform && i.profile == post.profile_id
          );
          if (item == null) {
            payloads.push({
              uid: this.currentUID,
              wid: this.WID,
              platform: post.platform,
              profile: post.profile_id,
            });
          }
        }
      });

      let requests = payloads.map((i) => {
        const fetchProfilePicture = httpsCallable(
          functions,
          "fetchProfilePicture"
        );
        return fetchProfilePicture(i).then((res) => {
          if (typeof res.data == "string") {
            this.profileImages[`${i.platform}.${i.profile}`] = res.data;
          } else {
            this.profileImages[`${i.platform}.${i.profile}`] = "";
          }
        });
      });
      await Promise.all(requests);
      return;
    },
    setView(view) {
      this.currentView = view;
    },
    nextMonth() {
      if (this.currentView === "week") {
        // Advance by one week
        let newDate = new Date(this.currentDate.getTime());
        newDate.setDate(this.currentDate.getDate() + 7);
        this.currentDate = newDate;
      } else {
        // Advance by one month
        let newDate = new Date(this.currentDate.getTime());
        newDate.setMonth(this.currentDate.getMonth() + 1);
        this.currentDate = newDate;
      }

      const startOfNextMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      );
      const endOfNextMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      );
      this.fetchPostsByDateRange(startOfNextMonth, endOfNextMonth);
    },
    previousMonth() {
      if (this.currentView === "week") {
        // Move back by one week
        let newDate = new Date(this.currentDate.getTime());
        newDate.setDate(this.currentDate.getDate() - 7);
        this.currentDate = newDate;
      } else {
        let newDate = new Date(this.currentDate.getTime());
        newDate.setMonth(this.currentDate.getMonth() - 1);
        this.currentDate = newDate;
      }
      const startOfPrevMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      );
      const endOfPrevMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      );
      this.fetchPostsByDateRange(startOfPrevMonth, endOfPrevMonth);
    },
    goToToday() {
      this.currentDate = new Date(); // Reset to the current date
      const startOfCurrentMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      );
      const endOfCurrentMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      );
      this.fetchPostsByDateRange(startOfCurrentMonth, endOfCurrentMonth);
    },
    formatsideDate(date, formatType = "default") {
    if (!date) return '';

    const options = { 
      weekday: 'short', 
      month: 'short', 
      day: '2-digit' 
    };

    return date.toLocaleDateString('en-US', options); // Formats to 'Fri, Feb 07'
  },
    formatDate(date, formatType = "default") {
      const isMobile = window.innerWidth <= 768;
      let options;

      if (formatType === "sidebar") {
        // Format for the sidebar
        options = { year: "numeric", month: "short", day: "numeric" };
      } else {
        // Default or mobile format
        options = isMobile
          ? { month: "short", year: "numeric" } // Short month format for mobile
          : { month: "long", year: "numeric" }; // Full month format otherwise
      }

      return date.toLocaleDateString("en-US", options);
    },
    isToday(day) {
      const today = new Date();
      return (
        day.getDate() === today.getDate() &&
        day.getMonth() === today.getMonth() &&
        day.getFullYear() === today.getFullYear()
      );
    },
    getAdditionalPostsCount(day) {
      const postsForDay = this.posts[day.toISOString().split('T')[0]];
      if (postsForDay) {
        const filteredPosts = postsForDay.filter(
          (post) => post.post_status !== 'deleted'
        );
        return filteredPosts.length - 2; // Return the additional post count
      }
      return 0;
    },
    showPostsInSidebar(day) {
      this.selectedDay = day;
      this.sidebarOpen = true;
    },
    toggleOnboardingVisibility() {
      this.isOnboardingVisible = !this.isOnboardingVisible;
    },
    async fetchOnboardingSteps() {
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.onboarding_steps) {
            const stepsData = data.onboarding_steps;
            this.onboardingSteps = this.onboardingSteps.map((step) => ({
              ...step,
              completed: stepsData[step.key] || false,
            }));
          }
          this.dismissed =
            data.dismissed !== undefined ? data.dismissed : false;
        } else {
          console.log("No user data found in Firestore, using defaults.");
        }
      } catch (error) {
        console.error("Error fetching onboarding data from Firestore: ", error);
      }
    },
    async dismissOnboarding() {
      this.dismissed = true;
      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, { dismissed: this.dismissed });
        console.log("Dismiss state updated in Firestore!");
      } catch (error) {
        console.error("Error updating dismiss state in Firestore: ", error);
      }
    },
    async initializeOnboardingSteps() {
      const stepsData = {};
      this.onboardingSteps?.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists() || !docSnap.data().onboarding_steps) {
        await setDoc(docRef, { onboarding_steps: stepsData }, { merge: true });
        console.log("Onboarding steps initialized in Firestore.");
      } else {
        console.log("Firestore already has onboarding steps.");
      }
    },
    async updateOnboardingStepsInFirestore() {
      const stepsData = {};
      this.onboardingSteps?.forEach((step) => {
        stepsData[step.key] = step.completed;
      });

      try {
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, { onboarding_steps: stepsData });
        console.log("Onboarding steps updated in Firestore successfully!");
      } catch (error) {
        console.error("Error updating onboarding steps in Firestore: ", error);
      }
    },
    displayedWeeklyPosts(day) {
      const dateStr = day.toISOString().split("T")[0];
      if (
        this.expandedWeeklyDay &&
        this.expandedWeeklyDay.toISOString() === day.toISOString()
      ) {
        return this.posts[dateStr]; // Return all posts if the day is expanded
      }
      return this.posts[dateStr].slice(0, 20); // Return only the first 10 posts otherwise
    },
    toggleWeeklyDay(day) {
      if (
        this.expandedWeeklyDay &&
        this.expandedWeeklyDay.toISOString() === day.toISOString()
      ) {
        this.expandedWeeklyDay = null; // Collapse the day if it is already expanded
      } else {
        this.expandedWeeklyDay = day; // Expand the selected day
      }
    },
  },
};
</script>

<style scoped>
.date-cell {
  height: 150px; /* Adjust the height as needed */
  width: 100%; /* Ensure full width within the grid cell */
  position: relative; /* Ensure relative positioning for absolute child elements */
}
.date-week {
  height: 580px; /* Adjust the height as needed */
  width: 100%; /* Ensure full width within the grid cell */
  position: relative; /* Ensure relative positioning for absolute child elements */
}
.plus-btn-container {
  position: absolute;
  top: 0;
  right: 0;
  display: none; /* Hide the plus button by default */
}

.date-cell:hover .plus-btn-container {
  display: block; /* Show the plus button when the date cell is hovered */
}

.plusbtn {
  border: 1px solid gray; /* Add border here */
  padding: 1px 8px;
  border-radius: 10%;
  background-color: white;
}

.today-highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #8050de;
  color: white;
  border-radius: 50%;
  font-weight: bold;
}
.ghg {
  flex: 1 0 auto;
  max-width: 100%;
}
.post-container {

  padding: 2px; /* Add padding to prevent content from touching the edges */
  margin-bottom: 2px; /* Add some space between posts */
  background-color: white; /* Optional: Change background color */
}
.plusbtn {
  border: 1px solid gray;
  padding: 1px 8px;
  border-radius: 10%;
  background-color: white;
}

button.text-lg {
  font-size: 20px; /* Increase button size */
}
.day-names-row {
  margin-bottom: 0.5rem;
}
.day-name-cell {
  height: 24px;
  font-size: 0.875rem; /* Adjust the font size as needed */
}

.date-container {
  width: 150px; /* Adjust width as needed */
}
.info-btn {
  background: none;
  border: none;
  color: #3182ce;
  cursor: pointer;
}
.dot-indicator {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.dot-blue {
  background-color: #3182ce; /* Blue for posts */
}
.dot-orange {
  background-color: #ffa500; /* Orange for holidays */
}
.sidebar {
  width: 300px;
  position: relative; /* Ensure sidebar is positioned next to the calendar */
  flex-shrink: 0; /* Prevent sidebar from shrinking */
}
.main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .lg\:grid-rows-6 {
    grid-template-rows: repeat(
      3,
      minmax(0, 1fr)
    ); /* Adjusted to create 6 rows */
  }
}
.sidebar {
  width: 300px;
  position: relative; /* Ensure sidebar is positioned next to the calendar */
  flex-shrink: 0; /* Prevent sidebar from shrinking */
}
.main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .lg\:grid-rows-6 {
    grid-template-rows: repeat(
      3,
      minmax(0, 1fr)
    ); /* Adjusted to create 6 rows */
  }
}
.custom-hover-gradient {
  transition: background 0.3s ease;
}
.custom-hover-gradient:hover {
  background: linear-gradient(to top left, #aea3a3, #f8fafc);
}
.date-passed {
  background-color: #fafcff; /* Gray background color */
  color: #9e9e9e; /* Gray text color */
}
.platform-logo {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  object-fit: cover;
}

.post-image {
  width: 100%; /* Ensure it takes up the full container width */
  height: auto; /* Maintain aspect ratio */
  max-height: 100px; /* Limit image height */
}
.sidebar {
  position: absolute; /* Position sidebar over the calendar */
  top: 0.5; /* Align to the top of the relative container */
  right: 0; /* Align to the right side of the container */
  width: 300px; /* Fixed width for the sidebar */
  height: 46.8rem; /* Maximum height of the sidebar */
  overflow-y: auto; /* Enable scrolling inside the sidebar */
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1); /* Optional: Adds left shadow for depth */
  background-color: #fff; /* Background color for visibility */
  transition: transform 0.3s ease-in-out; /* Smooth transition for toggling visibility */
  transform: translateX(100%); /* Start offscreen */
  padding: 10px;
}
.sticky-header {
  height: 4rem;
  position: sticky;
  top: -12px;
  background-color: white; /* Ensure the header background doesn't blend with the content */
  z-index: 10; /* Keeps it above the other content while scrolling */
}
.platform-logo img {
  display: inline-block;
}
img {
  max-height: 20rem;
  object-fit: contain;
}
.break-all {
  word-break: break-all;
}
.onboarding-popup {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 300px;
  background-color: black;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color: white;
}
.onboarding-popup h2 {
  margin-top: 0;
  color: white;
}
.checkbox-item {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #565454;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: #333;
  color: white;
}
.checkbox-item input[type="checkbox"] {
  margin-right: 10px;
}
.progress-text {
  margin-top: 10px;
  color: white;
  font-size: 14px;
}
.progress-bar {
  width: 100%;
  background-color: #555;
  border-radius: 5px;
  margin-top: 5px;
  height: 10px;
}
.progress-bar-fill {
  height: 100%;
  background-color: #8056de;
  width: 0%;
  border-radius: 5px;
}
.custom-checkbox {
  display: none;
}
.custom-checkbox + label {
  position: relative;
  cursor: pointer;
  padding-left: 25px;
}
.custom-checkbox + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: white;
}
.custom-checkbox + label:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4caf50;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}
.custom-checkbox:checked + label:after {
  transform: scale(1);
}
.dismiss-button {
  display: block;
  width: 100%;
  padding: 5px 20px;
  margin-top: 1px;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}
.dismiss-button:hover {
  background-color: #8056de;
}
.need-help-link {
  color: white;
  text-decoration: none;
}
.need-help-link:hover {
  text-decoration: underline;
}
.onboarding-link {
  color: white !important;
  text-decoration: none !important;
}
.onboarding-link:hover {
  text-decoration: underline !important;
}
.strikethrough {
  text-decoration: line-through;
  color: #ccc;
}
.icon-color {
  color: white;
}
.video-container {
  max-height: 400px; /* Adjust the maximum height as needed */
  width: 100%;
  object-fit: contain; /* Ensures the video fits within the container without cropping */
}

/* Additional styles to maintain aspect ratio */
.video-container::before {
  content: "";
  display: block;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  position: relative;
}
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* Additional styles to maintain aspect ratio */
.video-container::before {
  content: "";
  display: block;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  position: relative;
}
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.half-img {
  width: 50%; /* Each image takes up half the width */
  height: 100%;
  object-fit: cover; /* Ensure the image covers the half without distortion */
  position: absolute; /* Position the images absolutely within their container */
  top: 0; /* Align the top edge */
}

.half-img:first-child {
  left: 0; /* Align the first image to the left */
  border-top-left-radius: 50%; /* Round the left corners */
  border-bottom-left-radius: 50%;
}

.half-img:last-child {
  right: 0; /* Align the second image to the right */
  border-top-right-radius: 50%; /* Round the right corners */
  border-bottom-right-radius: 50%;
}

.platform-logo {
  display: flex;
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  object-fit: cover;
}

.flex-container {
  display: flex;
  width: 48px; /* Total width of the combined image container */
  height: 24px; /* Height of the combined image container */
  position: relative; /* Ensure the images can be absolutely positioned */
}

.post-container {
  padding: 1px; /* Add padding to prevent content from touching the edges */
  margin-bottom: 2px; /* Add some space between posts */
  background-color: white; /* Optional: Change background color */
}

.plusbtn {
  border: 1px solid gray;
  padding: 1px 8px;
  border-radius: 10%;
  background-color: white;
}
.post-card-container {
   /* Adjust the width as needed */
  width: 100%; /* Ensure full width */
  margin: 0 auto; /* Center the container */
  margin-top: 66px;
}

.md\:w-full {
  width: 100%;
}

@media (max-width: 768px) {
  .date-week {
    height: 150px; /* Adjusted height for small screens */
  }
}
.skeleton-loader .skeleton-post-container {
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton-loader .skeleton-platform-logo,
.skeleton-loader .skeleton-text {
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}
.holiday-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
